<template>
  <v-btn-toggle
    class="d-flex justify-center elevation-3 rounded-xl"
    v-model="buttonToggled"
    :dark="isReady"
    @change="onToggledClick"
  >
    <v-btn
      :dark="!isReady && !buttonDisabled"
      block
      :disabled="buttonDisabled"
      :loading="loading"
      :color="isReady ? 'accent' : 'primary'"
    >
      {{ isReady ? readyLabel : notReadyLabel }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'GameToggleButton',
  props: [
    'notReadyLabel',
    'readyLabel',
    'selected',
    'loading',
    'disabled'
  ],
  data () {
    return {
      buttonToggled: undefined,
      currentLabel: '',
      isReady: false
    }
  },
  computed: {
    buttonDisabled () {
      if (this.disabled === undefined) {
        return false
      } else {
        return this.disabled
      }
    }
  },
  methods: {
    onToggledClick () {
      this.isReady = this.buttonToggled === 0
      this.$emit('toggleClick', this.isReady)
    }
  },
  mounted () {
    this.isReady = this.selected
    this.isReady ? this.buttonToggled = 0 : this.buttonToggled = undefined
  }
}
</script>
