import Vue from 'vue'
import Vuex from 'vuex'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import router from '../router/index'
import Sentencer from 'sentencer'
import createPersistedState from 'vuex-persistedstate'
import { scenarios } from '@/games/scenarios/all_scenarios'

Vue.use(Vuex)

// Admin Vuex store module containing all the specifics for the admin capabilities
const moduleAdmin = {
  // Setting so that you call this.$store.dispatch('admin/setUserinfo' ...)
  namespaced: true,

  state: () => ({
    user: {},
    activeGameStatus: null,
    activeGameQuestion: null,
    activeGame: null,
    games: [],
    loading: false,
    activeTeams: [],
    gameScores: {},
    firebaseUser: undefined
  }),
  mutations: {
    setUserInfo (state, val) {
      state.user = val
    },
    setActiveGame (state, val) {
      // console.log('Setting active game: ' + val)
      state.activeGame = val
      if (val !== null) {
        state.activeGameStatus = val.status
        state.activeGameQuestion = val.currentQuestion
      } else {
        state.activeTeams = []
        state.activeGameQuestion = null
        state.gameScores = {}
      }
    },
    addGame (state, val) {
      for (const g in state.games) {
        if (state.games[g].id === val.id) {
          return
        }
      }
      state.games.push(val)
    },
    removeGame (state, place) {
      state.games.splice(place, 1)
    },
    changeGame (state, payload) {
      if (payload.position >= 0 && payload.position < state.games.length) {
        state.games.splice(payload.position, 1, payload.value)
      }
    },
    emptyGameList (state) {
      state.games = []
    },
    setLoadingGames (state, val) {
      state.loading = val
    },
    addChangeActiveTeams (state, payload) {
      const newval = {}
      newval.code = payload.name
      newval.name = payload.value.name
      newval.answers = payload.value.games
      newval.active = payload.value.loggedIn
      for (let i = 0; i < state.activeTeams.length; i++) {
        if (state.activeTeams[i].code === payload.name) { // key equals key
          state.activeTeams.splice(i, 1, newval)
          return
        }
      }
      state.activeTeams.push(newval)
    },
    resetActiveTeams (state) {
      state.activeTeams = []
    },
    setCurrentQuestion (state, val) {
      state.activeGameQuestion = val
    },
    setGameScores (state, snapshot) {
      // Loop over every game key and set the values in the JSON
      for (const game in snapshot) {
        Vue.set(state.gameScores, game, snapshot[game])
      }
    },
    setFirebaseUser (state, user) {
      state.firebaseUser = user
    }
  },
  getters: {
    getNumberOfGames (state) {
      return state.games.length
    },
    activeGameStatus (state) {
      return state.activeGameStatus
    },
    userInfo (state) {
      return state.user
    },
    activeGame (state) {
      // console.log('Fetching activegame: ' + state.activeGame)
      return state.activeGame
    },
    areGamesLoading (state) {
      return state.loading
    },
    loadedGames (state) {
      return state.games
    },
    activeTeams (state) {
      return state.activeTeams
    },
    activeGameQuestion (state) {
      if (state.activeGameQuestion === null || state.activeGameStatus === 'initial') {
        return '-'
      } else {
        return state.activeGameQuestion
      }
    },
    gameScores: (state) => (name) => {
      if (name !== undefined) {
        return state.gameScores[name]
      } else {
        return state.gameScores
      }
    }
  },
  actions: {
    // Login functionality
    async login ({ commit }, userInput) {
      const { user } = await firebase.auth().signInWithEmailAndPassword(userInput.email, userInput.password)
      commit('setUserInfo', user)
      router.push('/dashboard/library')
    },
    async logout ({ commit, getters }) {
      firebase.auth().signOut()
      commit('emptyGameList')
      commit('setUserInfo', null)
    },
    // Game editing and creating functionality - FOR ADMINS
    async createGame ({ commit, dispatch }, payload) {
      const defaultimagename = 'defaultimage.png'
      // const defaultimageurl = 'https://firebasestorage.googleapis.com/v0/b/mobileuhgame.appspot.com/o/game%2Fdefaultimage.png?alt=media&token=be1e976f-c57b-4616-af1f-b95e35ad179d'
      const game = {
        title: payload.title,
        description: payload.description,
        date: payload.date,
        nbrOfGroups: payload.nbrOfGroups,
        groups: [],
        imageExt: '', // Keeping this to be able to remove image later
        gametype: payload.gametype,
        color: payload.color,
        status: 'initial',
        currentQuestion: 1,
        modified: payload.modified
      }

      // Pushing to the database, and setting up the groups as well
      const gamepath = await firebase.database().ref('game').push(game)
      dispatch('setGroups', { groups: game.groups, nbrOfGroups: game.nbrOfGroups, gameid: gamepath.key })

      // Setting up the image
      let downloadURL = ''
      if (payload.image !== null) {
        const fileending = payload.image.name.split('.')[1]
        await firebase.storage().ref('game/' + gamepath.key + '.' + fileending).put(payload.image)
        await firebase.database().ref('game/' + gamepath.key).update({ imageExt: fileending }) // Can be optimized, but is it necessary?
        downloadURL = await firebase.storage().ref('game/' + gamepath.key + '.' + fileending).getDownloadURL()
      } else {
        downloadURL = await firebase.storage().ref('game/' + defaultimagename).getDownloadURL()
      }
      await firebase.database().ref('game').child(gamepath.key).update({ imageURL: downloadURL })
    },
    loadAllGames ({ commit, getters, dispatch }) {
      commit('setLoadingGames', true)
      firebase.database().ref('game').once('value',
        function (data) {
          if (data.numChildren() === 0) {
            commit('setLoadingGames', false)
          }
        })
      firebase.database().ref('game').on('child_added',
        function (data) {
          const obj = data.val()
          const loadedGame = ({
            id: data.key,
            title: obj.title,
            description: obj.description,
            date: obj.date,
            imageURL: obj.imageURL,
            imageExt: obj.imageExt,
            gametype: obj.gametype,
            nbrOfGroups: obj.nbrOfGroups,
            groups: obj.groups,
            color: obj.color,
            status: obj.status,
            currentQuestion: obj.currentQuestion,
            modified: obj.modified
          })
          commit('addGame', loadedGame)
          commit('setLoadingGames', false)
        })
      firebase.database().ref('game').on('child_removed',
        function (data) {
          for (let i = 0; i < getters.getNumberOfGames; i++) {
            if (data.key === getters.loadedGames[i].id) {
              // Removing game from game list
              commit('removeGame', i)
              return
            }
            if (getters.activeGame !== null && getters.activeGame.id === data.key) {
              dispatch('setActiveGame', null)
            }
          }
        })
      firebase.database().ref('game').on('child_changed',
        function (data) {
          // console.log('Got child changed')
          const value = data.val()
          value.id = data.key // Adding the ID
          for (let i = 0; i < getters.getNumberOfGames; i++) {
            if (value.id === getters.loadedGames[i].id) {
              commit('changeGame', { position: i, value: value })
            }
            // If the active game changes such as during gameplay we must update active game accordingly
            if (getters.activeGame !== null && getters.activeGame.id === value.id) {
              commit('setActiveGame', value)
            }
          }
        })
    },
    async setGroups ({ commit }, { groups, nbrOfGroups, gameid }) {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const codelength = 4
      let resultcode = ''
      groups = Object.values(groups) // Convert any JSON shit to ordinary array so that we can use array prototype methods
      // Get all the current groups we have, we can get them from the shortcutlist!
      const groupssnap = await (await firebase.database().ref('groups').once('value')).val()
      const occupiedGroupCodes = []
      for (var group in groupssnap) {
        occupiedGroupCodes.push(group.key)
      }

      if (nbrOfGroups > 0) {
        // Create new codes
        const shortcutgroups = {}
        let groupnr = 1
        while (groupnr <= nbrOfGroups) {
          // Create a random code
          for (var i = codelength; i > 0; --i) resultcode += chars[Math.round(Math.random() * (chars.length - 1))]
          // Create a random name with 'Team' plus a noun
          if (!groups.includes(resultcode) && !occupiedGroupCodes.includes(resultcode)) {
            groups.push(resultcode)
            shortcutgroups[resultcode] = {}
            let nounLower = ''
            // A max total length of 15 for 'Team <noun>'
            do {
              nounLower = Sentencer.make('{{ noun }}')
            } while (nounLower.length > 10)
            const nounUpper = nounLower.charAt(0).toUpperCase() + nounLower.slice(1)
            shortcutgroups[resultcode] = ({ name: 'Team ' + nounUpper, loggedIn: false, game: gameid })
            groupnr++
          }
          resultcode = ''
        }
        await firebase.database().ref('game/' + gameid + '/groups').set(groups)
        await firebase.database().ref('groups').update(shortcutgroups)
      } else if (nbrOfGroups < 0) {
        // Remove groups
        let key = ''
        for (let i = 0; i < Math.abs(nbrOfGroups); i++) {
          key = groups.pop()
          firebase.database().ref('groups/' + key).remove()
        }
        await firebase.database().ref('game/' + gameid + '/groups').set(groups)
      }
    },
    async editGame ({ commit, dispatch }, { newGame, oldGame }) {
      // Check if there's a change in group size, oldGame still has all its groups, newgame has another property called groupSize
      const groupDifference = newGame.groupSize - oldGame.nbrOfGroups
      if (groupDifference !== 0) {
        dispatch('setGroups', { groups: oldGame.groups, nbrOfGroups: groupDifference, gameid: oldGame.id })
      }

      // See if we have changed the image
      let fileending = oldGame.imageExt
      let downloadURL = oldGame.imageURL
      if (newGame.image != null) {
        fileending = newGame.image.name.split('.')[1]
        await firebase.storage().ref('game/' + oldGame.id + '.' + fileending).put(newGame.image)
        downloadURL = await firebase.storage().ref('game/' + oldGame.id + '.' + fileending).getDownloadURL()
      }
      const game = {
        title: newGame.title,
        description: newGame.description,
        date: newGame.date,
        color: newGame.color,
        gametype: oldGame.gametype, // We can't change gametype yet
        imageExt: fileending,
        nbrOfGroups: newGame.groupSize,
        imageURL: downloadURL
      }
      await firebase.database().ref('game/' + oldGame.id).update(game)
    },
    removeGame ({ commit }, game) {
      // We set the file ending and thus there is a picture uploaded
      if (game.imageExt !== '') {
        firebase.storage().ref('game/' + game.id + '.' + game.imageExt).delete()
      }
      firebase.database().ref('game/' + game.id).remove()
      // , now we remove the groups associated to that game too
      const groups = game.groups
      for (let j = 0; j < groups.length; j++) {
        firebase.database().ref('groups/' + groups[j]).remove()
      }
      // Also clear the score
      firebase.database().ref('score/' + game.id).remove()
    },
    stopLoadingAllGames ({ commit }) {
      firebase.database().ref('game').off()
      commit('emptyGameList')
    },
    setActiveGame ({ commit, dispatch }, val) {
      // Set the active game and start listening to changes etc.
      // If we are removing the active game, or replacing it, and we have listeners out there already, we handle them
      dispatch('stopListeningToActiveGameGroups')
      commit('resetActiveTeams')
      commit('setActiveGame', val)
      dispatch('listenToActiveGameGroups')
    },
    listenToActiveGameGroups ({ commit, getters }) {
      const activeGame = getters.activeGame
      if (activeGame !== null && activeGame !== undefined) {
        for (const g in activeGame.groups) {
          firebase.database().ref('groups/' + activeGame.groups[g]).on('value', function (snapshot) {
            commit('addChangeActiveTeams', { name: snapshot.key, value: snapshot.val() })
          })
        }
        // Listen for the score as well
        firebase.database().ref('score/' + getters.activeGame.id).on('value', function (snapshot) {
          // console.log('Recieved a score update')
          commit('setGameScores', snapshot.val())
        })
      }
    },
    stopListeningToActiveGameGroups ({ getters }) {
      const activeGame = getters.activeGame
      if (activeGame !== null && activeGame !== undefined) {
        for (const g in activeGame.groups) {
          firebase.database().ref('groups/' + activeGame.groups[g]).off()
        }
        firebase.database().ref('score/' + getters.activeGame.id).off()
      }
    },
    async setActiveGameStatus ({ getters }, val) {
      const updatedVal = { status: val }
      await firebase.database().ref('game/' + getters.activeGame.id).update(updatedVal)
    },
    async setCurrentQuestion ({ getters }, val) {
      const updatedVal = { currentQuestion: val }
      await firebase.database().ref('game/' + getters.activeGame.id).update(updatedVal)
    },
    async updateGamePoints ({ getters }, payload) {
      const code = payload.code
      const points = payload.points
      const game = payload.game
      const name = payload.name
      await firebase.database().ref('score/' + getters.activeGame.id + '/' + game).update({ [code]: { points: points, name: name } })
    },
    async updateTeamName ({ getters }, payload) {
      const code = payload.code
      const name = payload.name
      // First we update the group settings, then we have to update every god damn
      await firebase.database().ref('groups/' + code).update({ name: name })

      const snapshot = await firebase.database().ref('score/' + getters.activeGame.id).once('value')
      const allScores = snapshot.val()
      // If we have no score right now, then ignore
      if (allScores == null) {
        return
      }
      for (const gameIndex in allScores) {
        const gameScore = allScores[gameIndex]
        if (gameScore[code] !== undefined) {
          gameScore[code].name = name
        }
        allScores[gameIndex] = gameScore
      }
      // console.log(allScores)
      await firebase.database().ref('score/' + getters.activeGame.id).update(allScores)
    }
  }
}

// Client capabilities in this module instead
const moduleClient = {
  namespaced: true,

  state: () => ({
    // All info under the database record of the user logged in
    userInfo: null,
    // Their code they logged in with
    userCode: null,
    // The gametype e.g. TheUltimateScenario
    gameType: null,
    // The status of the game, retrieved from the database record of the game
    gameStatus: undefined,
    // Which question the game is currently at
    currentQuestion: undefined,
    // All the current scores for the game
    gameScores: null,
    // Current score for the current question
    currentTeamScore: 0,
    // All the modified games
    modifiedGamesList: undefined,
    // List of all user codes
    numberOfTeams: null
  }),
  mutations: {
    setUserCode (state, val) {
      state.userCode = val
    },
    setUserInfo (state, val) {
      state.userInfo = val
    },
    // Is only set by firebase callbacks to inform game of eventual changes
    setGameStatus (state, val) {
      state.gameStatus = val
    },
    setGameType (state, val) {
      state.gameType = val
    },
    setCurrentQuestion (state, val) {
      state.currentQuestion = val
    },
    setGameScores (state, val) {
      state.gameScores = val
    },
    setCurrentScore (state, val) {
      if (val === undefined || val === null) {
        state.currentTeamScore = 0
      } else {
        state.currentTeamScore = val
      }
    },
    setModifiedGames (state, val) {
      state.modifiedGamesList = val
    },
    setNumberOfTeamsPlaying (state, val) {
      state.numberOfTeams = val
    }
  },
  getters: {
    userInfo (state) {
      return state.userInfo
    },
    userCode (state) {
      return state.userCode
    },
    gameStatus (state) {
      return state.gameStatus
    },
    isLoggedIn (state) {
      return state.userCode !== null
    },
    gameType (state) {
      return state.gameType
    },
    currentQuestion (state) {
      return state.currentQuestion
    },
    historicGameValue: (state) => (name) => {
      if (state.userInfo.games !== undefined && state.userInfo.games[name] !== undefined) {
        return state.userInfo.games[name].answers
      } else {
        return undefined
      }
    },
    historicGameReady: (state) => (name) => {
      if (state.userInfo.games !== undefined && state.userInfo.games[name] !== undefined) {
        return state.userInfo.games[name].ready
      } else {
        return undefined
      }
    },
    gameScores: (state) => (name) => {
      if (name !== undefined) {
        return state.gameScores[name]
      } else {
        return state.gameScores
      }
    },
    currentScore (state) {
      return state.currentTeamScore
    },
    modifiedGames (state) {
      return state.modifiedGamesList
    },
    numberOfTeamsTotal (state) {
      const list = []
      for (const game in state.gameScores) {
        for (const group in state.gameScores[game]) {
          if (!list.includes(group)) {
            list.push(group)
          }
        }
      }
      return list.length
    }
  },
  actions: {
    async login ({ commit, getters, rootGetters }, userInput) {
      // Fetching game id from the database to match and find the correct game type
      const snapshotGroup = await firebase.database().ref('groups/' + userInput).once('value')
      if (snapshotGroup.exists()) {
        const groupValue = snapshotGroup.val()
        commit('setUserCode', userInput)

        if (groupValue.uid === undefined) {
          let data = {}
          try {
            data = await firebase.auth().createUserWithEmailAndPassword(userInput + '@noemail.com', userInput + userInput)
          } catch (error) {
            console.log(error)
          }
          groupValue.uid = data.user.uid
          firebase.database().ref('groups/' + userInput).update({ uid: groupValue.uid })
        } else {
          await firebase.auth().signInWithEmailAndPassword(userInput + '@noemail.com', userInput + userInput)
        }

        // Setting the rest of the info, so that we can fetch game updates later as well
        commit('setUserInfo', groupValue)
        commit('setGameStatus', null)

        // Fetching at least one update from the game status (such as if scenario etc.)
        const snapshotGame = await firebase.database().ref('game/' + getters.userInfo.game).once('value')
        if (snapshotGame.exists()) {
          const game = snapshotGame.val()
          const gameType = rootGetters.scenarioComponentNameByFriendlyName(game.gametype)

          commit('setGameStatus', game.status)
          commit('setGameType', gameType)
          commit('setCurrentQuestion', game.currentQuestion)
          commit('setModifiedGames', game.modified)
        } else {
          throw new Error('Group found, but not the game!?')
        }

        // Fetching the scores as well, just once
        const snapshotScore = await firebase.database().ref('score/' + getters.userInfo.game).orderByChild('points').once('value')
        if (snapshotScore.exists()) {
          const gamesInScenario = rootGetters.scenarioGamesByComponentName(getters.gameType)
          const currentGame = gamesInScenario[getters.currentQuestion]
          const newScores = snapshotScore.val()
          // We do a quick check to see if we as a team have a score or not
          if (newScores !== null && newScores[currentGame] !== undefined &&
              newScores[currentGame][getters.userCode] !== undefined) {
            commit('setCurrentScore', newScores[currentGame][getters.userCode].points)
          }
          commit('setGameScores', snapshotScore.val())
        }

        // dispatch('listenToGameUpdates')
        router.push('/game')
      } else {
        throw new Error('No group found')
      }
    },
    async logout ({ commit, dispatch, getters }) {
      if (getters.userCode !== null) {
        // dispatch('stopListeningToUpdates')
        await firebase.auth().signOut()
        commit('setUserCode', null)
        commit('setUserInfo', null)
      }
    },
    async updateGamePoints ({ getters }, game) {
      await firebase.database().ref('score/' + getters.userInfo.game + '/' + game.name).update({ [getters.userCode]: { points: game.points, name: getters.userInfo.name } })
    },
    // Update the values that are for the specific team (under groups) such as answers, name and so on
    async updateTeamValues ({ commit, getters }, values) {
      // commit('setUserInfo', values)
      // Updating team values
      await firebase.database().ref('groups/' + getters.userCode).update(values)
    },
    // Exists because it was in "login" before and disappears during page refresh in the game
    listenToGameUpdates ({ commit, dispatch, getters, rootGetters }) {
      // First we reset to avoid lingering UI
      commit('setGameStatus', null)
      firebase.database().ref('game/' + getters.userInfo.game).on('value',
        function (data) {
          // We are interested in game status and game type and which question we are in
          if (data === null) {
            dispatch('stopListeningToUpdates')
            dispatch('logout')
          }
          const values = data.val()
          const gameType = rootGetters.scenarioComponentNameByFriendlyName(values.gametype)
          commit('setGameStatus', values.status)
          commit('setGameType', gameType)
          commit('setCurrentQuestion', values.currentQuestion)
          commit('setModifiedGames', values.modified)
        })
      firebase.database().ref('score/' + getters.userInfo.game).orderByChild('points').on('value',
        function (snapshot) {
          const gamesInScenario = rootGetters.scenarioGamesByComponentName(getters.gameType)
          const currentGame = gamesInScenario[getters.currentQuestion]
          const newScores = snapshot.val()
          // We do a quick check to see if we as a team have a score or not
          if (newScores !== null && newScores[currentGame] !== undefined &&
              newScores[currentGame][getters.userCode] !== undefined) {
            commit('setCurrentScore', newScores[currentGame][getters.userCode].points)
          }
          commit('setGameScores', snapshot.val())
        })
      // We listen to 'group' because we want updates if our team name has changed
      firebase.database().ref('groups/' + getters.userCode).on('value',
        function (data) {
          const values = data.val()
          if (values === null) {
            console.log('Something went horribly wrong')
            return
          }
          commit('setUserInfo', values)
        })
    },
    stopListeningToUpdates ({ getters }) {
      let gamepath = ''
      let code = ''
      if (getters.userInfo !== null) {
        gamepath = getters.userInfo.game
        code = getters.userCode
      }
      firebase.database().ref('game/' + gamepath).off()
      firebase.database().ref('score/' + gamepath).off()
      firebase.database().ref('groups/' + code).off()
    }
  }
}

export default new Vuex.Store({
  getters: {
    scenarioGamesByComponentName: (state) => (name) => {
      return scenarios[name].games
    },
    scenarioFriendlyNameByComponentName: (state) => (name) => {
      return scenarios[name].name
    },
    scenarioComponentNameByFriendlyName: (state) => (name) => {
      for (const s in scenarios) {
        if (scenarios[s].name === name) {
          return s
        }
      }
    },
    scenarioListWithFriendlyNames: (state) => {
      const list = []
      for (const s in scenarios) {
        list.push(scenarios[s].name)
      }
      return list
    },
    scenarioGamesByFriendlyName: (state, getters) => (name) => {
      const componentName = getters.scenarioComponentNameByFriendlyName(name)
      return getters.scenarioGamesByComponentName(componentName)
    }
  },
  modules: {
    client: moduleClient,
    admin: moduleAdmin
  },
  plugins: [createPersistedState({
    paths: ['client', 'admin'],
    storage: window.sessionStorage
  })]
})
