import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#388E3C',
        secondary: '#A5D6A7',
        teritary: '#00adee',
        accent: '#757575'
      }
    }
  }
})
