<template>
<v-container>
  <v-card
    max-width="700"
    elevation="20"
    rounded="xl"
    v-show="imageLoaded"
    class="pa-10 mb-10"
    color="teritary"
  >
  <v-img
      src="@/assets/logotype2.png"
      eager
      contain
      max-height="200"
      @load="imageLoaded = true"
    />
  </v-card>
  <v-card
    max-width="700"
    elevation="20"
    rounded="xl"
    v-show="imageLoaded"
    class="pt-5"
  >
    <v-card-text class="mt-5 px-10">
      <v-form
        @submit.prevent="buttonClicked"
      >
        <v-text-field
          label="Enter your secret code!"
          v-model="passcode"
          type="text"
          :error-messages="errormessage"
          color="accent"
          autofocus
          outlined
          autocomplete="new-password"
          rounded
          @input="errormessage = ''"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center pb-10 px-10">
      <v-btn color="primary"
        block
        x-large
        rounded
        :disabled="passcode == '' "
        :loading="buttonLoading"
        @click="buttonClicked"
      >Enter</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>

export default {
  name: 'LoginClient',
  data () {
    return {
      passcode: '',
      imageLoaded: false,
      buttonLoading: false,
      errormessage: '',
      links: [
        { text: 'About', link: 'About' },
        { text: 'Utmaningarnas Hus', href: 'http://www.utmaningarnashus.se', target: '_blank' },
        { text: 'Hosts', link: 'admin' }
      ]
    }
  },
  methods: {
    async buttonClicked () {
      if (this.passcode.length !== 0) {
        this.buttonLoading = true
        try {
          await this.$store.dispatch('client/login', this.passcode.trim().toUpperCase())
        } catch (ex) {
          this.errormessage = 'Unknown code, try again!'
        }
        this.buttonLoading = false
      }
    }
  },
  computed: {
    backgroundStyle () {
      return {
        backgroundImage: `url(${this.background})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundBlendMode: 'multiply'
      }
    }
  }
}
</script>
