<template>
    <v-card
      elevation="3"
      class="ma-5 pa-10"
      rounded="xl"
      outlined
    >
      <slot />
    </v-card>
</template>

<script>
export default {
  name: 'CardBackground'
}
</script>
