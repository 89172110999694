<template>
  <v-app>
      <Particles
        id="particleslogin"
        :options="config"
        :key="particlesKey"
        style="position: fixed;"
      />
      <v-main>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <v-fade-transition appear name="fade" mode="out-in">
                <component :is="client ? `LoginClient` : `LoginAdmin`" />
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <v-footer padless color="rgba(0.1,0.1,0.1,0.2)">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="link in links"
            :key="link.id"
            color="white"
            text
            rounded
            :to="link.link"
            :href="link.href"
            :target="link.target"
            class="my-2"
          >
            {{ link.text }}
          </v-btn>
          <v-col class="lighten-2 py-4 text-center white--text" cols="12">
            {{ new Date().getFullYear() }} — <strong>LevelUp</strong>
          </v-col>
        </v-row>
      </v-footer>
  </v-app>
</template>

<script>
import LoginAdmin from '@/components/LoginAdmin'
import LoginClient from '@/components/LoginClient'

export default {
  name: 'LoginPage',
  components: {
    LoginAdmin,
    LoginClient
  },
  props: ['client'],
  computed: {
    links () {
      return this.client ? this.clientLinks : this.adminLinks
    }
  },
  methods: {
    windowResize () {
      this.particlesKey += 1
    }
  },
  data () {
    return {
      particlesKey: 0,
      adminLinks: [{ text: 'Client Login', link: '/' }],
      clientLinks: [
        {
          text: 'About',
          href: 'https://www.utmaningarnashus.se/online-teambuilding/levelup',
          target: '_blank'
        },
        {
          text: 'Utmaningarnas Hus',
          href: 'https://www.utmaningarnashus.se',
          target: '_blank'
        },
        { text: 'Hosts', link: 'admin' }
      ],
      config: {
        autoPlay: true,
        detectRetina: true,
        background: {
          color: {
            value: '#E8F5E9'
          },
          position: '50% 50%',
          repeat: 'no-repeat',
          size: 'cover'
        },
        backgroundMode: {
          enable: true,
          zIndex: -1
        },
        fpsLimit: 90,
        particles: {
          color: {
            value: [
              '#1B5E20',
              '#4CAF50',
              '#388E3C',
              '#00adee',
              '#818181'
            ]
          },
          move: {
            attract: {
              rotate: {
                x: 600,
                y: 1200
              }
            },
            direction: 'bottom',
            enable: true,
            outModes: {
              bottom: 'destroy',
              left: 'destroy',
              right: 'destroy',
              top: 'out'
            },
            speed: 9
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.5
            }
          },
          size: {
            value: 20,
            random: {
              enable: true,
              minimumValue: 200,
              maximumValue: 400
            },
            animation: {
              enable: false,
              destroy: 'none',
              minimumValue: 200,
              startValue: 'min',
              speed: 10,
              sync: true
            }
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000
            },
            limit: 0,
            value: 0
          }
        },
        emitters: {
          direction: 'bottom',
          life: {},
          rate: {
            quantity: 1,
            delay: 0.1
          },
          size: {
            mode: 'percent',
            height: 0,
            width: 100
          },
          position: {
            x: 200,
            y: -90
          }
        }
      }
    }
  },
  created () {
    // To force particles and stuff
    window.addEventListener('resize', this.windowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  }

}
</script>
