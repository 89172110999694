<template>
  <v-container class="justify-center d-flex">
    <v-form v-model="valid">
        <v-snackbar
            v-model="statusSnackbar"
            :color="snackbarColor"
            :timeout="4000"
            :top="true"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                dark
                text
                v-bind="attrs"
                @click="statusSnackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <v-card
          outlined
            elevation="3"
            class="ma-10 pa-10"
            max-width="1000px"
            min-width="660px"
          >
          <strong>Spel Info</strong>
          <p>Grundläggande info om spelet</p>
          <v-text-field
            label="Namn"
            hint="Exempelvis företagsnamn eller likn."
            :rules="gameNameRule"
            v-model="gameName"
          />
          <v-textarea
            label="Beskrivning"
            hint="En extra text som kommer synas"
            rows="3"
            no-resize
            :rules="descriptionRule"
            v-model="description"
          />
          <v-file-input
            accept="image/*"
            show-size
            label="Bild/Logo  - (annars väljs LevelUp Logotypen)"
            v-model="image"
          />
          <v-container>
            <v-row
              class="pt-5"
              justify="space-around"
            >
              <v-col
              >
              <v-subheader class="pb-5 pt-5">När ska ni spela?</v-subheader>
                <v-date-picker v-model="date" />
              </v-col>
                <v-spacer />
              <v-col
              >
                <v-subheader class="pb-5 pt-5">Välj en färg för spelet</v-subheader>
                <v-color-picker v-model="color" />
              </v-col>
            </v-row>
          </v-container>
          </v-card>
          <v-card
            elevation="3"
            outlined
            class="ma-10 pa-10"
            max-width="1000px"
          >
          <strong>Grupp info</strong>
          <p>Hur många personer samt vilket spel ni kör</p>
            <v-text-field
              label="Antal grupper"
              v-model="groups"
              suffix="grupper"
              type="number"
              hint="Skriv hur många grupper som kommer spela"
              :rules="groupRule"
            />
            <v-select
              :items="scenarioListWithFriendlyNames"
              :rules="[(v) => !!v]"
              v-model="choosenGameType"
              label="Välj spel"
            />
          </v-card>
          <v-expand-transition>
            <v-card
              elevation="3"
              outlined
              class="ma-10 pa-10"
              max-width="1000px"
              v-show="modifiedGamesList.length > 0"
            >
              <strong>Spelspecifik info</strong>
              <template v-for="(n,index) in modifiedGamesList">
                <component
                  :key="index"
                  :is="modifiedGamesList[index].name"
                  :preData="modifiedGamesList[index].predata"
                  :gameData.sync="modifiedGamesValues"
                />
              </template>
            </v-card>
          </v-expand-transition>
          <v-card
            flat
            class="ma-10"
            max-width="1000px"
          >
          <v-btn
            block
            max-width="1000px"
            min-height="70px"
            color="success"
            elevation="3"
            :disabled="!valid"
            :loading="buttonLoading"
            v-on:click="saveGame"
          >Spara spel</v-btn>
          </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MemoryModifications from '@/games/Memory/Modifications'

export default {
  name: 'CreateNew',
  components: {
    MemoryModifications
  },
  data () {
    return {
      gameName: '',
      description: '',
      color: '',
      image: null,
      date: new Date().toISOString().substr(0, 10),
      valid: false,
      buttonLoading: false,
      gameType: [],
      choosenGameType: '',
      groups: '10',
      statusSnackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      modifiedGamesValues: {},
      numberRule: v => {
        if (!v.trim()) return true
        if (!isNaN(parseInt(v)) && v >= 1 && v <= 200) return true
        return 'Måste vara en siffra mellan 1 och 200'
      },
      gameNameRule: [
        v => !!v || 'Måste ha ett namn'
      ],
      descriptionRule: [
        v => !!v || 'Måste finnas en beskrivning',
        v => (v && v.length > 5 && v.length <= 200) || 'Måste vara mer en fem tecken och mindre än 200'
      ],
      selectRule: [
        v => !!v || 'Du måste välja ett spel'
      ],
      groupRule: [
        v => (!isNaN(parseInt(v)) && v >= 1) || 'Värdet måste vara en siffra större en 1'
      ]
    }
  },
  computed: {
    modifiedGamesList: function () {
      const modifiedGames = []
      if (!(this.choosenGameType === undefined || this.choosenGameType === '')) {
        const games = this.scenarioGamesByFriendlyName(this.choosenGameType)
        // Fetching all games that have the modifier flag and if its set to the modification component name
        // Observe that the component must be added to this view, then the "all_scenarios" must be modified accordingly
        for (const i in games) {
          if (games[i].modified !== undefined) {
            modifiedGames.push({ name: games[i].modified, predata: games[i].predata })
          }
        }
      }
      return modifiedGames
    },
    ...mapGetters([
      'scenarioGamesByFriendlyName',
      'scenarioListWithFriendlyNames'
    ])
  },
  methods: {
    async saveGame () {
      this.buttonLoading = true
      var didWeFail = false
      try {
        await this.$store.dispatch('admin/createGame', {
          title: this.gameName,
          description: this.description,
          date: this.date,
          nbrOfGroups: this.groups,
          image: this.image,
          gametype: this.choosenGameType,
          modified: this.modifiedGamesValues,
          color: this.color
        })
      } catch (ex) {
        console.error(ex)
        didWeFail = true
      }
      if (didWeFail) {
        this.buttonLoading = false
        this.setSnackBar(false)
      } else {
        this.buttonLoading = false
        this.$router.push({ name: 'GameLibrary', params: { builtGame: true } })
        // this.setSnackBar(true)
      }
    },
    setSnackBar (success) {
      if (success) {
        this.snackbarText = 'Spelet är sparat i databasen!'
        this.snackbarColor = 'success'
      } else {
        this.snackbarText = 'Något gick fel, kunde inte spara'
        this.snackbarColor = 'error'
      }
      this.statusSnackbar = true
    }
  }
}
</script>
