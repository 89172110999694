<template>
  <v-container style="color: #E8F5E9">
  <Particles
    id="particlesGameFinished"
    :options="config"
    :key="particlesKey"
    style="position: fixed;"
  />
  <CardBackground class="text-center teritary">
    <div class="title pt-5">Huzzah! Good job,</div>
    <div class="text-h4 mt-5">
      <p :style="'color:' + 'white'">{{ teamName }}</p>
    </div>
    <div class="title">you finished at</div>
    <p class="text-h4 mt-5" :style="'color:' + 'white'">
      <template v-if="scorePlace === 0">
        Last
      </template>
      <template v-else-if="scorePlace === 1">
        {{ scorePlace }}st
      </template>
      <template v-else-if="scorePlace === 2">
        {{ scorePlace }}nd
      </template>
      <template v-else-if="scorePlace === 3">
        {{ scorePlace }}rd
      </template>
      <template v-else>
        {{ scorePlace }}th
      </template>
      place
    </p>
    <template v-if="totalNumberOfTeams === 1">
      <p class="title">(you are playing by yourself ...)</p>
    >
    </template>
    <template v-else>
      <p class="title">(out of <b>{{ totalNumberOfTeams }}</b> teams)</p>
    </template>
    <div class="title">and you got:</div>
    <div class="text-h3 mt-5">
      <p :style="'color:' + 'white'">{{ totalScore }} points</p>
    </div>
  </CardBackground>
  <CardBackground
      class="my-5"
    >
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(s, index) in gameScores"
            :key="index"
          >
            <td class="text-button">{{s.name}}</td>
            <td><span class="title">{{ s.points }}</span> <span class="caption">p</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </CardBackground>
  <v-card elevation="0" color="transparent">
    <div class="title mt-5 pb-5 text-center">Thank you for playing our game, great job team! </div>
  </v-card>
  </v-container>
</template>

<script>
import CardBackground from '@/components/CardBackground.vue'

export default {
  name: 'GameFinished',
  props: [
    'teamName',
    'allScores',
    'allGames',
    'teamCode',
    'totalNumberOfTeams'
  ],
  components: {
    CardBackground
  },
  data () {
    return {
      particlesKey: 0,
      textColor: this.$vuetify.theme.themes.light.secondary,
      pointsColor: this.$vuetify.theme.themes.light.teritary,
      subtitleColor: this.$vuetify.theme.themes.light.primary,
      config: {
        autoPlay: true,
        detectRetina: true,
        background: {
          color: {
            value: '#E8F5E9'
          },
          position: '50% 50%',
          repeat: 'no-repeat',
          size: 'cover'
        },
        backgroundMode: {
          enable: true,
          zIndex: -1
        },
        fpsLimit: 90,
        particles: {
          color: {
            value: [
              '#1B5E20',
              '#4CAF50',
              '#388E3C',
              '#00adee',
              '#818181'
            ]
          },
          move: {
            attract: {
              rotate: {
                x: 600,
                y: 1200
              }
            },
            direction: 'bottom',
            enable: true,
            outModes: {
              bottom: 'destroy',
              left: 'destroy',
              right: 'destroy',
              top: 'out'
            },
            speed: 9
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.5
            }
          },
          size: {
            value: 20,
            random: {
              enable: true,
              minimumValue: 200,
              maximumValue: 400
            },
            animation: {
              enable: false,
              destroy: 'none',
              minimumValue: 200,
              startValue: 'min',
              speed: 10,
              sync: true
            }
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000
            },
            limit: 0,
            value: 0
          }
        },
        emitters: {
          direction: 'bottom',
          life: {},
          rate: {
            quantity: 1,
            delay: 0.1
          },
          size: {
            mode: 'percent',
            height: 0,
            width: 100
          },
          position: {
            x: 200,
            y: -90
          }
        }
      }
    }
  },
  methods: {
    windowResize () {
      this.particlesKey += 1
    }
  },
  computed: {
    gameScores () {
      const data = []
      for (const g in this.allGames) {
        const playedMission = this.allScores[this.allGames[g].name]
        if (playedMission !== undefined && playedMission[this.teamCode] !== undefined) {
          const gottenPoints = playedMission[this.teamCode].points
          data.push({
            name: this.allGames[g].friendlyname,
            points: gottenPoints
          })
        }
      }
      return data
    },
    totalScore () {
      let score = 0
      for (const g in this.gameScores) {
        score += this.gameScores[g].points
      }
      return score
    },
    sortedScoreListAll () {
      let totalScores = []

      for (const i in this.allScores) { // Every game (i)
        for (const j in this.allScores[i]) { // j team in i game
          const teamScore = this.allScores[i][j]

          // Now we loop through our array of objects and find the correct spot to add the points
          let found = false
          let t = 0

          while (!found) {
            // We haven't found it
            if (t === totalScores.length) {
              totalScores.push({ name: j, points: teamScore.points })
              found = true
            } else {
              if (totalScores[t].name === j) {
                // If there isn't any score registered, we don't show it as "0" points, so only show stuff that is registered
                totalScores[t].points += teamScore.points
                found = true
              } else {
                t++
              }
            }
          }
        }
      }
      totalScores = totalScores.sort(function (a, b) {
        return a.points - b.points
      })
      return totalScores.reverse()
    },
    scorePlace () {
      for (let i = 0; i < this.sortedScoreListAll.length; i++) {
        // console.log(this.sortedScoreListAll[i].name)
        if (this.sortedScoreListAll[i].name === this.teamCode) {
          return i + 1
        }
      }
      return 0
    }
  },
  created () {
    // To force particles and stuff
    window.addEventListener('resize', this.windowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  }
}
</script>
