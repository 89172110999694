<template>
  <v-container
    fill-height
    class="pa-0"
    fluid
    style="background-color: #BDBDBD"
  >
    <v-row
      class="justify-center align-center"
      no-gutters
    >
      <v-col
        cols="12"
        sm="10"
        md="7"
        lg="6"
      >
        <v-expand-transition
          mode="out-in"
          appear
        >
          <TeamStatus
            :teamName="userInfo.name"
            :questionNumber="currentQuestion"
            :scores="sortedScoreList"
            :teamCode="userCode"
            :showScore="gameStatus === 'score'"
            v-show="gameAnimationTrigger && showTeamStatus"
          />
        </v-expand-transition>
        <transition
          name="fade-transition"
          mode="out-in"
          @enter="gameAnimationTrigger = true"
          @leave="gameAnimationTrigger = false"
          appear
        >
          <component
            :is="displayingComponent"

            :teamName="userInfo.name"
            :loggedInPreviously="userInfo.loggedIn"
            @teamSettingsToggled="onTeamSettingsToggled"

            :topScores="sortedScoreList"
            highscoreTitle="Top scores!"
            :highscoreSubtitle="'Showing scores for \'Question ' + currentQuestion +'\''"
            :teamCode="userCode"
            @gameToggled="onGameToggled"

            :beforeHighscore="currentQuestion == maxNumberOfQuestions"
            :allScores="gameScores()"
            :allGames="scenarioGamesByComponentName(gameType)"
            :totalNumberOfTeams="numberOfTeamsTotal"

            @game-enter="gameAnimationTrigger = true"
            @game-leave="gameAnimationTrigger = false"

          />
          </transition>
        <CheckModal
          :dialog="showBackDialog"
          :title="titleBackDialog"
          :description="descriptionBackDialog"
          :textNegative="textNegativeBackDialog"
          :textPositive="textPositiveBackDialog"
          @negativeClick="showBackDialog = false"
          @positiveClick="onBackDialogPositivePressed"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// General imports
import TeamStatus from '@/components/TeamStatus.vue'
import TeamSettings from '@/games/TeamSettings'
import CheckModal from '@/components/CheckModal'
import ScoreFlash from '@/components/ScoreFlash'
import GamePaused from '@/components/GamePaused'
import GameFinished from '@/components/GameFinished'
import { mapGetters, mapActions } from 'vuex'

// Game scenario imports
import TheUltimateScenario from '@/games/scenarios/TheUltimateScenario'
import TheDigitalScenario from '@/games/scenarios/TheDigitalScenario'
import TheDigitalShortScenario from '@/games/scenarios/TheDigitalShortScenario'

export default {
  name: 'Game',
  components: {
    TeamSettings,
    CheckModal,
    ScoreFlash,
    GamePaused,
    GameFinished,
    TeamStatus,
    TheUltimateScenario,
    TheDigitalScenario,
    TheDigitalShortScenario
  },
  data () {
    return {
      backgroundColor: 'grey lighten-1',
      showBackDialog: false,
      gameAnimationTrigger: false,
      leavingGame: false,
      userLeft: false,
      titleBackDialog: 'Exit',
      descriptionBackDialog: 'Are you sure you want to exit the game?',
      textNegativeBackDialog: 'Cancel',
      textPositiveBackDialog: 'Yes',
      justEnteredThePage: true
    }
  },
  computed: {
    displayingComponent () {
      // If we are starting up or if we've started and the user hasn't had a chance to login
      if (this.gameStatus === 'initial' || this.userInfo.loggedIn === false) {
        return 'TeamSettings'
      } else if (this.gameStatus === 'score') {
        return 'ScoreFlash'
      } else if (this.gameStatus === 'paused') {
        return 'GamePaused'
      } else if (this.gameStatus === 'stopped') {
        return 'GameFinished'
      } else if (this.gameStatus !== null) {
        return this.gameType
      }
      return ''
    },
    sortedScoreList () {
      if (this.gameScores() === null) {
        return {}
      }
      const allGames = this.scenarioGamesByComponentName(this.gameType)
      const gameScore = this.gameScores(allGames[this.currentQuestion].name)
      const gameScoreArray = []
      // Putting individual objects in an array to use the sort function provided by JS
      for (const index in gameScore) {
        // Adding the index as a parameter, along with points and name! (for the score table later)
        const arrayScore = gameScore[index]
        arrayScore.code = index
        gameScoreArray.push(arrayScore)
      }
      gameScoreArray.sort(function (a, b) {
        return b.points - a.points
      })
      return gameScoreArray
    },
    maxNumberOfQuestions () {
      const allGames = this.scenarioGamesByComponentName(this.gameType)
      if (allGames === undefined) {
        return 0
      }
      return Object.keys(allGames).length
    },
    showTeamStatus () {
      if (this.displayingComponent !== 'TeamSettings' && this.displayingComponent !== 'GamePaused' &&
          this.displayingComponent !== 'GameFinished' && this.displayingComponent !== '') {
        return true
      }
      return false
    },
    ...mapGetters('client', [
      'userInfo',
      // Differs from userInfo loggedin, this concerns if user is logged in, in general. The other controls
      // if the user has entered its team name etc.
      'isLoggedIn',
      'userCode',
      'gameStatus',
      'gameType',
      'gameScores',
      'currentQuestion',
      'numberOfTeamsTotal'
    ]),
    ...mapGetters([
      'scenarioGamesByComponentName'
    ])
  },
  methods: {
    onBackPressed () {
      this.showBackDialog = true
    },
    onBackDialogPositivePressed () {
      this.leavingGame = true
      this.$router.push('/')
    },
    async onTeamSettingsToggled (name, toggled) {
      const updatedUserInfo = this.userInfo
      updatedUserInfo.name = name
      updatedUserInfo.loggedIn = toggled
      await this.updateTeamValues(updatedUserInfo)
    },
    async onGameToggled (gameName, version, answers, points, completed) {
      const updatedUserInfo = this.userInfo
      // Create the subcategory games if it does not exist, and the path to the question to
      if (updatedUserInfo.games === undefined) { updatedUserInfo.games = {} }
      if (updatedUserInfo.games[gameName] === undefined) { updatedUserInfo.games[gameName] = {} }

      if (completed) {
        updatedUserInfo.games[gameName].answers = answers
        await this.updateGamePoints({ name: gameName, points: points })
      }
      updatedUserInfo.games[gameName].version = version
      updatedUserInfo.games[gameName].ready = completed
      await this.updateTeamValues(updatedUserInfo)
    },
    ...mapActions('client', [
      'updateTeamValues',
      'updateGamePoints',
      'logout',
      'listenToGameUpdates',
      'stopListeningToUpdates'
    ])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.isLoggedIn) {
        // console.log('is not Logged In! (as a client)')
        next('/')
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if ((!this.isLoggedIn || this.leavingGame) && !this.userLeft) {
      // This is to check if we've:
      // - Entered by force, thus not logged in (through "beforeRouteEnter")
      // - We have completed the dialog check and are now exiting
      this.userLeft = true
      // console.log('stop listening to game updates')
      this.stopListeningToUpdates()
      this.logout()
    } else if (!this.leavingGame) {
      // We have exited through backbuttondialog
      this.showBackDialog = true
      next(false)
    } else {
      next()
    }
  },
  mounted () {
    this.listenToGameUpdates()
  }
}
</script>
