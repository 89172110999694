<template>
  <v-row justify="center">
    <v-form v-model="valid">
      <v-dialog :value="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ändra på ett spel</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Namn"
                    hint="Exempelvis företagsnamn eller likn."
                    :rules="gameNameRule"
                    v-model="newTitle"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Beskrivning"
                    rows="2"
                    :rules="descriptionRule"
                    v-model="newDescription"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="showDate"
                    :close-on-content-click="false"
                    :return-value.sync="newDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newDate"
                        label="Datum"
                        required
                        readonly
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="showDate = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(newDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Antal grupper"
                    v-model="newGroupSize"
                    suffix="grupper"
                    hint="Skriv hur många grupper som kommer spela"
                    :rules="groupRule"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-color-picker
                    hide-canvas
                    v-model="newColor"
                  >
                  </v-color-picker>
                </v-col>
                <v-col cols="12" sm="6">
                  <small>Lägg endast till nya bilder</small>
                  <v-file-input
                    label="Ändra bild"
                    v-model="newImage"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"
              text
              :disabled="loading"
              @click="onFalseClick"
            >
              Close
            </v-btn>
            <v-btn color="blue darken-1"
              text
              :loading="loading"
              @click="onPositiveClick"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-row>
</template>

<script>
export default {
  name: 'CheckModal',
  props: ['dialog', 'description', 'title', 'groupSize', 'date', 'color', 'loading'],
  data () {
    return {
      showDate: false,
      valid: false,
      changeButtonLoading: false,

      // New values
      newTitle: '',
      newDescription: '',
      newColor: '',
      newGroupSize: '',
      newImage: null,
      newDate: '',

      numberRule: v => {
        if (!v.trim()) return true
        if (!isNaN(parseInt(v)) && v >= 1 && v <= 200) return true
        return 'Måste vara en siffra mellan 1 och 200'
      },
      gameNameRule: [
        v => !!v || 'Måste ha ett namn'
      ],
      descriptionRule: [
        v => !!v || 'Måste finnas en beskrivning',
        v => (v && v.length > 5 && v.length <= 200) || 'Måste vara mer en fem tecken och mindre än 200'
      ],
      selectRule: [
        v => !!v || 'Du måste välja ett spel'
      ],
      groupRule: [
        v => (!isNaN(parseInt(v)) && v >= 1) || 'Värdet måste vara en siffra större en 1'
      ]
    }
  },
  methods: {
    onFalseClick () {
      this.$emit('negativeClick')
    },
    onPositiveClick () {
      const newgame = {
        title: this.newTitle,
        description: this.newDescription,
        date: this.newDate,
        color: this.newColor,
        groupSize: this.newGroupSize,
        image: this.newImage
      }
      this.$emit('positiveClick', newgame)
    }
  },
  watch: {
    dialog: function (data) {
      if (data) {
        this.newTitle = this.title
        this.newDescription = this.description
        this.newColor = this.color
        this.newGroupSize = this.groupSize
        this.newDate = this.date
      }
    }
  }
}
</script>
