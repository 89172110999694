/** All the scenarios
 * this file contains info about all the predefined scenarios that are available,
 * the main purpose is to give an order to scenarios so that the necessary files can load and so on.
 * Its used by both admin (create and supervise) and client side operations
 * - A friendly name is added as a feature to show titles with the highscore in the end (for the user), and for the admin in the future
 * to show what games are in a scenario
*/
export var scenarios =
{
  TheUltimateScenario: {
    name: 'Olles Ultimata Spel',
    games: {
      1: {
        name: 'TasteBudsGame',
        version: 'v1',
        friendlyname: 'Taste Buds'
      },
      2: {
        name: 'ShazamGame',
        version: 'v1',
        friendlyname: 'Shazam'
      },
      3: {
        name: 'TheCaseGame',
        version: 'v1',
        friendlyname: 'The Case'
      },
      4: {
        name: 'HollywoodGame',
        version: 'v1',
        friendlyname: 'Hollywood'
      },
      5: {
        name: 'ShapeUpGame',
        version: 'v1',
        friendlyname: 'Shape Up'
      },
      6: {
        name: 'MisfitGame',
        version: 'v1',
        friendlyname: 'Misfit'
      },
      7: {
        name: 'ThemeSongGame',
        version: 'v1',
        friendlyname: 'Theme Songs'
      },
      8: {
        name: 'MemoryGame',
        version: 'v1',
        friendlyname: 'Memory',
        modified: 'MemoryModifications',
        predata: 1
      },
      9: {
        name: 'TiebreakerGame',
        version: 'v1',
        friendlyname: 'The Tie Breaker'
      },
      10: {
        name: 'BobTheBuilderGame',
        version: 'v1',
        friendlyname: 'Bob The Builder'
      }
    }
  },
  TheDigitalScenario: {
    name: 'Det Digitala Spelet - Lång',
    games: {
      1: {
        name: 'ShazamGame',
        version: 'v1',
        friendlyname: 'Shazam'
      },
      2: {
        name: 'TasteBudsGame',
        version: 'v2',
        friendlyname: 'Cocktail'
      },
      3: {
        name: 'PhonyEyeGame',
        version: 'v1',
        friendlyname: 'Phony Eye'
      },
      4: {
        name: 'MisfitGame',
        version: 'v2',
        friendlyname: 'Misfit'
      },
      5: {
        name: 'DigitalEightGame',
        version: 'v1',
        friendlyname: 'Digital 8'
      },
      6: {
        name: 'HollywoodGame',
        version: 'v1',
        friendlyname: 'Hollywood'
      },
      7: {
        name: 'MemoryGame',
        version: 'v1',
        friendlyname: 'Memory',
        modified: 'MemoryModifications',
        predata: 2
      },
      8: {
        name: 'ThemeSongGame',
        version: 'v1',
        friendlyname: 'Theme Songs'
      },
      9: {
        name: 'SlogansGame',
        version: 'v1',
        friendlyname: 'Slogans'
      },
      10: {
        name: 'TheTouristGame',
        version: 'v1',
        friendlyname: 'The Tourist'
      }
    }
  },
  TheDigitalShortScenario: {
    name: 'Det Digitala Spelet - Kort',
    games: {
      1: {
        name: 'ShazamGame',
        version: 'v1',
        friendlyname: 'Shazam'
      },
      2: {
        name: 'TasteBudsGame',
        version: 'v2',
        friendlyname: 'Cocktail'
      },
      3: {
        name: 'MisfitGame',
        version: 'v2',
        friendlyname: 'Misfit'
      },
      4: {
        name: 'DigitalEightGame',
        version: 'v1',
        friendlyname: 'Digital 8'
      },
      5: {
        name: 'HollywoodGame',
        version: 'v1',
        friendlyname: 'Hollywood'
      },
      6: {
        name: 'MemoryGame',
        version: 'v1',
        friendlyname: 'Memory',
        modified: 'MemoryModifications',
        predata: 2
      },
      7: {
        name: 'SlogansGame',
        version: 'v1',
        friendlyname: 'Slogans'
      }
    }
  }
}
