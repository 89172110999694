<template>
  <v-container>
    <v-row>
      <v-card elevation="3" class="ma-5 pa-10" max-width="350px">
        <v-row class="d-flex justify-center align-center">
          <p class="text-caption text-decoration-underline mr-5">Status:</p>
          <p class="text-button mr-5">{{ statusMessage }}</p>
          <p class="text-caption text-decoration-underline mr-5">Fråga</p>
          <p class="text-body font-weight-bold">{{ activeGameQuestion }}</p>
        </v-row>
        <v-col class="d-flex justify-center">
          <v-layout align-center justify-center>
            <v-btn
              elevation="3"
              class="mr-5 py-10 rounded-l-xl"
              :disabled="disabledPreviousArrow"
              @click="previousQuestionClicked"
            >
              <v-icon>mdi-skip-previous</v-icon>
            </v-btn>
            <v-btn-toggle
              class="d-flex flex-column"
              :mandatory="!gameWaiting"
              @change="controllerPressed"
              v-model="activeControlButton"
            >
              <v-btn
                active-class="green"
                elevation="3"
                :disabled="gameFinished"
                class="px-10 mt-5 rounded-t-xl"
              >
                <v-icon center>mdi-play</v-icon>
              </v-btn>
              <v-btn
                active-class="yellow"
                elevation="3"
                :disabled="gameWaiting || gameFinished"
                class="px-10 my-5"
              >
                <v-icon center>mdi-pause</v-icon>
              </v-btn>
              <v-btn
                active-class="red"
                elevation="3"
                :disabled="gameWaiting"
                class="px-10 mb-5 rounded-b-xl"
              >
                <v-icon center>mdi-stop</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn
              elevation="3"
              class="ml-5 py-10 rounded-r-xl"
              :disabled="disabledNextArrow"
              @click="nextQuestionClicked"
            >
              <v-icon>mdi-skip-next</v-icon>
            </v-btn>
          </v-layout>
        </v-col>
        <v-divider class="mt-5 mb-10" />
        <v-btn elevation="3"
          block
          :to="{ name: 'Highscore' }"
          class="mt-5 d-flex"
        >
          <v-icon left>mdi-open-in-new</v-icon>
          High Score
        </v-btn>
        <v-btn elevation="3" block class="mt-5 d-flex" @click="showTeamsDialog = true">
          <v-icon left>mdi-account-group</v-icon>Tillgängliga lag</v-btn>
      </v-card>
      <v-card elevation="3" min-height="400" class="ma-5 pa-10 flex-grow-1">
        <v-card-title>Specifika kontroller</v-card-title>
        <v-alert type="info" outlined>Implementeras i framtiden</v-alert>
      </v-card>
    </v-row>
    <v-row>
      <v-card elevation="3" class="ma-5 pa-10 flex-grow-1">
        <v-card-title>Spelresultat</v-card-title>
          <v-data-table :headers="headers" :items="teamsInTable" no-data-text="Väntar på att folk ska välja lagnamn">
            <template v-slot:[`item.name`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.name"
                save-text="Spara"
                cancel-text="Avbryt"
                large
                @save="dialogSaveTeamName(props.item.name, props.item.code)"
              >
                <span
                  v-show="props.item.nameloading === false"
                >
                {{ props.item.name}}
                </span>
                <v-progress-circular
                  v-show="props.item.nameloading === true"
                  indeterminate
                  :size="20"
                  :width="3"
                  color="primary"
                />
                <template v-slot:input>
                  <div class="mt-4 title">Lagnamn</div>
                  <v-text-field
                    v-model="props.item.name"
                    :rules="[max15chars,min1chars]"
                    label="Namn på teamet"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template
              v-for="n in maxNumberOfQuestions"
              v-slot:[`item.q${n}score`] = "props"
            >
              <div :key="n">
                <v-edit-dialog
                  :return-value.sync="props.item['q' + n + 'score']"
                  v-if="props.item['q' + n + 'score'] !== undefined"
                  large
                  save-text="Spara"
                  cancel-text="Avbryt"
                  @save="dialogSaveGamePoints(n, props.item.code, props.item['q' + n + 'score'])"
                >
                   <span
                    v-show="props.item['q' + n + 'loading'] === false">
                     {{ props.item['q' + n + 'score'] }}
                   </span>
                  <v-icon
                    small
                    color="green darken-2"
                    v-show="props.item['q' + n + 'ready'] === true && !props.item['q' + n + 'loading']"
                  >
                    mdi-check-bold
                  </v-icon>
                  <v-progress-circular
                    v-show="props.item['q' + n + 'loading']"
                    indeterminate
                    :size="20"
                    :width="3"
                    color="primary"
                  />
                  <template v-slot:input>
                    <div class="mt-4 title">{{'Fråga ' + n}}</div>
                    <v-text-field
                      v-model="props.item['q' + n + 'score']"
                      :rules="[max10points]"
                      label="Poäng"
                      type="number"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
            </template>
            <template v-slot:[`item.total`] = "props">
              <v-progress-circular
                v-show="props.item['total' + 'loading']"
                indeterminate
                :size="20"
                :width="3"
                color="primary"
              />
              <span
                v-show="props.item['total' + 'loading'] === false">
                  {{ props.item.total }}
              </span>
            </template>

          </v-data-table>
      </v-card>
    </v-row>
  <CheckModal
    :dialog="showStopDialog"
    title="Avsluta Spelet"
    description="OBS: DET GÅR INTE ATT ÅNGRA

Du kommer visa highscore för användaren!"
    :pre="true"
    :bold="true"
    textNegative="Nej"
    textPositive="Japp"
    @negativeClick="stopGameAverted"
    @positiveClick="stopGamePressed"
  />
  <TeamsModal
    :dialog.sync="showTeamsDialog"
    :teams="nonActiveTeams"
  />
  </v-container>
</template>
<script>
import CheckModal from '@/components/CheckModal'
import TeamsModal from '@/components/TeamsModal'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CheckModal,
    TeamsModal
  },
  data () {
    return {
      gameWaiting: true, // True initially, this governs the initial state and sets disbled to certain buttons etc.
      gameFinished: false, // If this is set the game ends and the user can't undo it
      showStopDialog: false,
      showTeamsDialog: false,
      max10points: (v) => (v <= 10 && v >= 0) || 'Får vara mellan 0 och 10',
      max15chars: (v) => v.length <= 15 || 'Högst 15 karaktärer',
      min1chars: (v) => v.length > 0 || 'Du måste ha ett värde',
      pagination: {},
      stateBeforePausePressed: undefined,
      activeControlButton: '',
      activeGameStatusFix: ''
    }
  },
  computed: {
    teamsInTable () {
      const teams = []
      for (let i = 0; i < this.activeTeams.length; i++) {
        if (this.activeTeams[i].active) {
          const team = {}
          team.name = this.activeTeams[i].name
          team.code = this.activeTeams[i].code
          team.total = 0
          team.nameloading = false
          const teamAnswers = this.activeTeams[i].answers
          const gameInScenario = this.scenarioGamesByFriendlyName(this.activeGame.gametype)
          for (const g in gameInScenario) {
            const answer = teamAnswers === undefined ? undefined : teamAnswers[gameInScenario[g].name]
            const scores = this.gameScores(gameInScenario[g].name)

            this.$set(team, 'q' + g + 'score', (scores === undefined || scores[team.code] === undefined) ? 0 : parseInt(scores[team.code].points, 10))
            this.$set(team, 'q' + g + 'loading', false)
            this.$set(team, 'q' + g + 'ready', answer === undefined ? false : answer.ready)
            this.$set(team, 'q' + g + 'answers', answer === undefined ? undefined : answer.answers)
            this.$set(team, 'total', team.total + team['q' + g + 'score'])
            this.$set(team, 'total' + 'loading', false)
          }
          teams.push(team)
        }
      }
      return teams
    },
    nonActiveTeams () {
      const teams = []
      for (let i = 0; i < this.activeTeams.length; i++) {
        if (!this.activeTeams[i].active) {
          const team = {}
          team.name = this.activeTeams[i].name
          team.code = this.activeTeams[i].code
          teams.push(team)
        }
      }
      return teams
    },
    headers () {
      const array = []
      array.push({ text: 'Lagnamn', value: 'name' })
      array.push({ text: 'Lagkod', value: 'code' })

      let i = 1
      while ((i - 1) < this.maxNumberOfQuestions) {
        array.push({ text: 'Fråga ' + i, value: 'q' + i + 'score' })
        i++
      }
      array.push({ text: 'TOTAL', value: 'total' })
      return array
    },
    maxNumberOfQuestions () {
      const scenarioGames = this.scenarioGamesByFriendlyName(this.activeGame.gametype)
      if (scenarioGames === undefined) {
        return 0
      }
      return Object.keys(scenarioGames).length
    },
    statusMessage () {
      let status = ''
      switch (this.activeGameStatus) {
        case 'initial':
          status = 'Väntar'
          break
        case 'running':
          status = 'Spelar'
          break
        case 'stopped':
          status = 'Stoppad'
          break
        case 'paused':
          status = 'Pausad'
          break
        case 'score':
          status = 'Poäng'
          break
        default:
          return ''
      }
      // Ett retarded fix för att watchers inte fungerar tydligen
      this.setButtonStatuses(status)
      return status
    },
    disabledNextArrow () {
      return this.gameWaiting || this.gameFinished || this.activeGameStatus === 'paused' ||
        (this.activeGameQuestion >= this.maxNumberOfQuestions && this.activeGameStatus === 'score')
    },
    disabledPreviousArrow () {
      return this.gameWaiting || this.gameFinished || this.activeGameStatus === 'paused' ||
        this.activeGameQuestion <= 1
    },
    routeToHighscore () {
      return this.$router.resolve({ name: 'Highscore', params: { activeGame: this.activeGame, gameScores: this.gameScores } })
    },
    ...mapGetters('admin', [
      'activeTeams',
      'activeGame',
      'activeGameStatus',
      'activeGameQuestion',
      'gameScores'
    ]),
    ...mapGetters([
      'scenarioGamesByFriendlyName'
    ])
  },
  methods: {
    controllerPressed () {
      let status = ''
      // Play pressed
      if (this.activeControlButton === 0) {
        // Did we pause before? if so then we handle it

        this.stateBeforePausePressed === undefined ? status = 'running' : status = this.stateBeforePausePressed
        this.stateBeforePausePressed = undefined // and reset it
        this.gameWaiting = false
      // Pause pressed
      } else if (this.activeControlButton === 1) {
        this.stateBeforePausePressed = this.activeGameStatus
        status = 'paused'
      // Stop pressed
      } else if (this.activeControlButton === 2) {
        // Show dialog first and handle it from there, only if you haven't pressed stop before (obviously)
        if (!this.gameFinished) {
          this.showStopDialog = true
        }
        return
      }
      this.setActiveGameStatus(status)
    },
    setButtonStatuses (value) {
      switch (value) {
        case 'Poäng':
        case 'Spelar':
          this.gameWaiting = false
          this.gameFinished = false
          this.activeControlButton = 0
          return
        case 'Stoppad':
          this.gameFinished = true
          this.gameWaiting = false
          this.activeControlButton = 2
          return
        case 'Pausad':
          this.gameWaiting = false
          this.gameFinished = false
          this.activeControlButton = 1
          return
        case 'Väntar':
          this.gameWaiting = true
          this.gameFinished = false
          this.activeControlButton = undefined
      }
    },
    nextQuestionClicked () {
      // TODO Do different stuff if you have pause pressed or not. Score is not shown if browsing with pause pressed!
      if (this.activeGameStatus === 'paused') {
        this.setCurrentQuestion(this.activeGameQuestion + 1)
      } else if (this.activeGameStatus === 'score') {
        this.setCurrentQuestion(this.activeGameQuestion + 1)
        this.setActiveGameStatus('running')
      } else {
        this.setActiveGameStatus('score')
      }
    },
    previousQuestionClicked () {
      // TODO Do different stuff if you have pause pressed or not. Score is not shown if browsing with pause pressed!
      this.setCurrentQuestion(this.activeGameQuestion - 1)
    },
    async dialogSaveTeamName (name, code) {
      for (let i = 0; i < this.teamsInTable.length; i++) {
        const currentTeam = this.teamsInTable[i]
        if (currentTeam.code === code) {
          currentTeam.nameloading = true
          await this.updateTeamName({ code: code, name: name })
          currentTeam.nameloading = false
        }
      }
    },
    async dialogSaveGamePoints (index, code, points) {
      const scenarioGames = this.scenarioGamesByFriendlyName(this.activeGame.gametype)
      if (typeof points === 'string') {
        points = parseInt(points, 10)
      }
      for (let i = 0; i < this.teamsInTable.length; i++) {
        const currentTeam = this.teamsInTable[i]
        if (currentTeam.code === code) {
          this.$set(currentTeam, 'total' + 'loading', true)
          this.$set(currentTeam, 'q' + index + 'loading', true)
          await this.updateGamePoints({ code: code, game: scenarioGames[index].name, points: points, name: currentTeam.name })
          this.$set(currentTeam, 'q' + index + 'loading', false)
          this.$set(currentTeam, 'total' + 'loading', false)
        }
      }
    },
    stopGameAverted () {
      if (this.activeGameStatus === 'running' || this.activeGameStatus === 'score') {
        this.activeControlButton = 0
      } else if (this.activeGameStatus === 'paused') {
        this.activeControlButton = 1
      } else if (this.activeGameStatus === 'initial') {
        this.gameWaiting = true
        this.activeControlButton = undefined
      }
      this.showStopDialog = false
    },
    stopGamePressed () {
      this.gameFinished = true
      this.showStopDialog = false
      this.setActiveGameStatus('stopped')
    },
    ...mapActions('admin', [
      'setActiveGameStatus',
      'setCurrentQuestion',
      'listenToActiveGameGroups',
      'stopListeningToActiveGameGroups',
      'updateGamePoints',
      'updateTeamName'
    ])
  },
  created () {
    // Since we are setting an "activeGame" we want to cancel that listening and reinitiate it
    // this exists as a precaution if the user refreshes the site, also we want to preload, hence the update
    // in activeGame
    this.stopListeningToActiveGameGroups()
    this.listenToActiveGameGroups()
  },
  destroyed () {
    this.stopListeningToActiveGameGroups()
  }
}
</script>
