<template>
  <v-app>
      <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    // Main vue file, we let the router take care of everything from here
  }),
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'LevelUp by Utmaningarnas Hus'
      },
      immediate: true
    }
  }
}
</script>
