<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      max-width="400"
      @click:outside="onFalseClick"
    >
      <v-card
        dark
      >
        <v-card-title
          class="headline error"
        >
          {{ title }}
        </v-card-title>
        <template v-if="pre">
          <v-card-text class="error font-weight-bold"><pre>{{ description }}</pre></v-card-text>
        </template>
        <template v-else>
          <v-card-text class="error">
            {{ description }}
          </v-card-text>
        </template>
        <v-card-actions class="error">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="onFalseClick"
          >
            {{ textNegative }}
          </v-btn>
          <v-btn
            color="black"
            text
            @click="onPositiveClick"
          >
            {{ textPositive }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'CheckModal',
  props: [
    'dialog',
    'textNegative',
    'textPositive',
    'description',
    'title',
    'pre'
  ],
  methods: {
    onFalseClick () {
      this.$emit('negativeClick')
    },
    onPositiveClick () {
      this.$emit('positiveClick')
    }
  }
}
</script>
