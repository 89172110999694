<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-row class="d-flex justify-center">
      <v-col
        :cols="12"
      >
        <v-row
          no-gutters
          class="justify-center"
        >
          <SevenSegmentDisplay
            class="mx-1"
            v-for="n in lengthOfNumber"
            :value="answer.length >= n ? answer[n-1] : ''"
            :key="n"
            :rounded="true"
            :segment-width="23"
            :segment-height="4"
            :on-color="answer.length >= n ? `green` : `WhiteSmoke`"
            off-color="WhiteSmoke"
          />
        </v-row>
        <v-text-field
          v-model="answer"
          outlined
          class="centered-input mt-5"
          hint="Write your answer and send it!"
          autofocus
          :rules="[maxlengthRule]"
          type="number"
          rounded
          @update:error="(error) => hasError = error"
          :disabled="isReady"
        />
      </v-col>
    </v-row>
    <GameToggleButton
      class="mt-5"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :loading="isSending"
      :disabled="hasError"
      :notReadyLabel="notReadyLabel"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'
import SevenSegmentDisplay from 'vue-seven-segment-display'

export default {
  name: 'DigitalEightGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton,
    SevenSegmentDisplay
  },
  data () {
    return {
      title: 'Digital 8',
      subtitle: 'Combine the information and try to figure out the 5-digit code!',
      databaseString: 'v1',
      lengthOfNumber: 5,
      maxlengthRule: (v) => v.length <= this.lengthOfNumber || 'Too many numbers!',
      isSending: false,
      isReady: false,
      notReadyLabel: 'Are you sure?',
      readyLabel: 'Answer locked!',
      hasError: false,
      answer: ''
    }
  },
  methods: {
    async onToggledClick (toggled) {
      this.isReady = toggled
      this.isSending = true
      if (this.isReady) {
        const points = await this.correctAnswers(this.answer)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, this.answer, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answer) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0
      for (let i = 0; i < answer.length; i++) {
        if (facts[i] === answer[i]) {
          matches += 2
        }
      }
      return matches
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    if (this.previousValues !== undefined) {
      this.answer = this.previousValues
    }
  }
}
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: large;
  }

</style>
