<template>
  <v-overlay
    opacity="1"
  >
    <div class="title">{{ titleText }}</div>
    <div class="body">{{ bodyText }}</div>
  </v-overlay>
</template>

<script>
export default {
  name: 'GamePaused',
  props: [
    'beforeHighscore'
  ],
  data () {
    return {
      titleNormal: 'Oh! we\'ve paused the game!',
      bodyNormal: 'Waiting for the host to resume ...',

      titleScore: 'Yay! Good job so far!',
      bodyScore: 'Pay attention to your host ...'
    }
  },
  computed: {
    titleText () {
      return this.beforeHighscore ? this.titleScore : this.titleNormal
    },
    bodyText () {
      return this.beforeHighscore ? this.bodyScore : this.bodyNormal
    }
  }
}
</script>
