<template>
  <v-container class="d-flex justify-center flex-column">
    <v-snackbar
      v-model="statusSnackbar"
      color='success'
      :timeout="4000"
      :top="true"
    >
      Spelet är sparat i databasen!

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="statusSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card v-for="gameInst in games" :key="gameInst.id"
      :elevation="choosenGame !== null && choosenGame.id === gameInst.id ? 20 : 3"
      class="ma-5"
      outlined
      max-width="1000px"
    >
      <v-divider />
      <div class="py-5" :style="{'background-color': gameInst.color }"/>
      <v-divider/>
      <v-img
        class="white--text align-end"
        height="250px"
        eager
        :src="gameInst.imageURL"
      >
      <v-divider/>
      </v-img>
      <v-row>
        <v-col class="mr-5">
          <v-card-title>
            {{ gameInst.title }}
          </v-card-title>
          <v-card-subtitle>
            {{ gameInst.description }}
          </v-card-subtitle>
          <v-card-subtitle>
            Antal Grupper: <b>{{ gameInst.nbrOfGroups }} </b>
          </v-card-subtitle>
        </v-col>
        <v-col class="text-right ml-5">
          <v-card-subtitle class="subtitle-1">
            Start datum: <b>{{ gameInst.date }}</b>
          </v-card-subtitle>
          <v-card-subtitle class="text-overline">
            {{ gameInst.gametype }}
          </v-card-subtitle>
        </v-col>
      </v-row>
      <v-divider/>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click.stop="setDashboardActiveGame(gameInst)"
        >
          <template v-if="choosenGame === null || choosenGame.id !== gameInst.id">
            Övervaka
          </template>
          <template v-else>
            Sluta
          </template>
        </v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.stop="clickShowChangeDialog(gameInst)"
        >
          Ändra
        </v-btn>
        <v-btn
          color="error"
          text
          @click.stop="clickShowDeleteDialog(gameInst)"
        >
          Ta bort
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear
        color="primary"
        height="15"
        indeterminate
        striped
        v-show="showLoading"
      />
    <v-alert
      dense
      outlined
      transition="scale-transition"
      prominent
      type="info"
      v-show="showEmptyText"
    >
      Finns inget i biblioteket, lägg till <strong>nya spel</strong> först!
    </v-alert>
    <CheckModal
      :dialog="showDeleteDialog"
      :title="removeTitle"
      :description="removeDescription"
      :textNegative="removeCancel"
      :textPositive="removeOk"
      @negativeClick="clickAbort"
      @positiveClick="clickRemoveGame"
    />
    <ChangeModal
      :dialog="showChangeDialog"
      :loading="changeButtonLoading"
      :title="changeTitle"
      :description="changeDescription"
      :groupSize="changeGroups"
      :date="changeDate"
      :color="changeColor"
      @negativeClick="clickAbort"
      @positiveClick="clickChangeGame"
    />
  </v-container>
</template>

<script>
import CheckModal from '@/components/CheckModal.vue'
import ChangeModal from '@/components/ChangeModal.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GameLibrary',
  components: {
    CheckModal,
    ChangeModal
  },
  data () {
    return {
      statusSnackbar: false,

      showChangeDialog: false,
      showDeleteDialog: false,
      selectedToDeleteOrEditGame: null,

      // Dialog to remove
      removeDescription: 'Är du säker på att du vill ta bort detta spel?',
      removeTitle: 'Ta bort',
      removeCancel: 'Avbryt',
      removeOk: 'Japp!',

      // Dialog to change
      changeTitle: '',
      changeDescription: '',
      changeGroups: '',
      changeColor: '',
      changeDate: '',
      changeButtonLoading: false
    }
  },
  props: [
    'builtGame'
  ],
  computed: {
    showEmptyText () {
      return this.getNumberOfGames === 0 && !this.showLoading
    },
    ...mapGetters('admin', {
      choosenGame: 'activeGame',
      games: 'loadedGames',
      showLoading: 'areGamesLoading',
      getNumberOfGames: 'getNumberOfGames'
    })
  },
  methods: {
    clickAbort () {
      this.showChangeDialog = false
      this.showDeleteDialog = false
    },
    clickRemoveGame () {
      // Deleting the game, first we check if you've choosen it and if you have we remove
      // the selection before removing it altogether
      this.showDeleteDialog = false
      if (this.choosenGame !== null && this.selectedToDeleteOrEditGame.id === this.choosenGame.id) {
        this.setActiveGame(null)
      }
      this.removeGame(this.selectedToDeleteOrEditGame)
      this.selectedToDeleteOrEditGame = null
    },
    async clickChangeGame (editedGame) {
      // Dispatch to store that an game has been edited
      this.changeButtonLoading = true
      await this.editGame({ newGame: editedGame, oldGame: this.selectedToDeleteOrEditGame })
      this.changeButtonLoading = false
      this.showChangeDialog = false
      this.selectedToDeleteOrEditGame = null
    },
    clickShowChangeDialog (game) {
      this.selectedToDeleteOrEditGame = game // Saving the old values in one object
      this.changeTitle = game.title
      this.changeDescription = game.description
      this.changeGroups = game.nbrOfGroups
      this.changeDate = game.date
      this.changeColor = game.color
      this.showChangeDialog = true
    },
    clickShowDeleteDialog (game) {
      this.selectedToDeleteOrEditGame = game
      this.showDeleteDialog = true
    },
    setDashboardActiveGame (game) {
      // If selected previously, unselect and if its first time, select it and make sure the user knows
      if (this.choosenGame !== null && this.choosenGame.id === game.id) {
        this.setActiveGame(null)
      } else {
        this.setActiveGame(null)
        this.setActiveGame(game)
      }
    },
    ...mapActions('admin', [
      'setActiveGame',
      'editGame',
      'removeGame'
    ])
  },
  mounted () {
    this.statusSnackbar = this.builtGame
  }

}
</script>
