export var cities =
  [
    'Adak',
    'Apia',
    'Pago Pago',
    'Alofi',
    'Avarua',
    'Honolulu',
    'Hilo',
    'Anchorage',
    'Papeete',
    'Fairbanks',
    'Sitka',
    'Whitehorse',
    'Juneau',
    'Adamstown',
    'Vancouver',
    'Portland',
    'San Francisco',
    'Seattle',
    'Sacramento',
    'Los Angeles',
    'Riverside',
    'San Diego',
    'Tijuana',
    'Mexicali',
    'Las Vegas',
    'Yellowknife',
    'Calgary',
    'Edmonton',
    'Phoenix',
    'Salt Lake City',
    'Tucson',
    'Hanga Roa',
    'Saskatoon',
    'Albuquerque',
    'El Paso',
    'Chihuahua',
    'Denver',
    'Durango',
    'Regina',
    'Zapopan',
    'Guadalajara',
    'Monterrey',
    'Mexico City',
    'San Antonio',
    'Puebla',
    'Austin',
    'Oklahoma City',
    'Wichita',
    'Winnipeg',
    'Dallas',
    'Veracruz',
    'Houston',
    'Kansas City',
    'Minneapolis',
    'Quetzaltenango',
    'Guatemala City',
    'New Orleans',
    'Memphis',
    'Mérida',
    'San Salvador',
    'Belmopan',
    'Belize City',
    'Milwaukee',
    'Chicago',
    'Tegucigalpa',
    'Nashville',
    'Managua',
    'Indianapolis',
    'Louisville',
    'Cincinnati',
    'Atlanta',
    'San José',
    'Detroit',
    'Columbus',
    'Tampa',
    'Havana',
    'Cleveland',
    'Jacksonville',
    'George Town',
    'Tokyo',
    'Charlotte',
    'Miami',
    'Pittsburgh',
    'Guayaquil',
    'Panama City',
    'Toronto',
    'Buffalo',
    'Raleigh',
    'Quito',
    'Rochester',
    'Nassau',
    'Washington',
    'Lima',
    'Kingston',
    'Baltimore',
    'Cali',
    'Virginia Beach',
    'Ottawa',
    'Medellín',
    'Cartagena',
    'Philadelphia',
    'Barranquilla',
    'Forked River',
    'Jersey City',
    'Bogotá',
    'Montreal',
    'Iquitos',
    'Valdivia',
    'Concepción',
    'Cusco',
    'Maracaibo',
    'Valparaíso',
    'Arequipa',
    'Providence',
    'La Serena',
    'Cockburn Town',
    'Boston',
    'Punta Arenas',
    'Santiago',
    'Antofagasta',
    'Iquique',
    'Oranjestad',
    'Santo Domingo',
    'Rio Branco',
    'Willemstad',
    'Calama',
    'Mendoza',
    'Iqaluit',
    'Ushuaia',
    'La Paz',
    'Puerto Williams',
    'Caracas',
    'Fredericton',
    'San Juan',
    'Saint John',
    'Sucre',
    'Charlotte Amalie',
    'Hamilton',
    'Road Town',
    'Córdoba',
    'Porto Velho',
    'Charlottetown',
    'The Valley',
    'Basse-Terre',
    'Bahía Blanca',
    'St. John\'s',
    'San Fernando',
    'Roseau',
    'Chaguanas',
    'Kingstown',
    'Castries',
    'Rosario',
    'Boa Vista',
    'Manaus',
    'Bridgetown',
    'Buenos Aires',
    'Stanley',
    'Asunción',
    'Saint-Pierre',
    'Montevideo',
    'Cuiabá',
    'Paramaribo',
    'Campo Grande',
    'Chuí',
    'Pelotas',
    'Cayenne',
    'Nuuk',
    'Porto Alegre',
    'Macapá',
    'Assis',
    'Curitiba',
    'Belém',
    'Brasília',
    'Campinas',
    'São Paulo',
    'Vitória',
    'Ilhéus',
    'Fortaleza',
    'Maceió',
    'Recife',
    'Ponta Delgada',
    'Praia',
    'Reykjavík',
    'Dakar',
    'Thiès',
    'Serekunda',
    'Brikama',
    'Banjul',
    'Nouakchott',
    'Bissau',
    'Conakry',
    'Freetown',
    'El Aaiún',
    'Monrovia',
    'Lisbon',
    'Porto',
    'Bamako',
    'Casablanca',
    'Koulikoro',
    'Rabat',
    'Tórshavn',
    'Dublin',
    'Seville',
    'Belfast',
    'Jamestown',
    'Gibraltar',
    'Yamoussoukro',
    'Douglas',
    'Málaga',
    'Glasgow',
    'Abidjan',
    'Madrid',
    'Cardiff',
    'Edinburgh',
    'Timbuktu',
    'Liverpool',
    'Bilbao',
    'Manchester',
    'Aberdeen',
    'Birmingham',
    'Leeds',
    'Nantes',
    'Ouagadougou',
    'Tamale',
    'Valencia',
    'Accra',
    'London',
    'Greenwich',
    'Lomé',
    'Toulouse',
    'Ibiza',
    'Niamey',
    'Barcelona',
    'Paris',
    'Cotonou',
    'Porto-Novo',
    'Palma',
    'Algiers',
    'Lagos',
    'Ibadan',
    'The Hague',
    'Brussels',
    'Antwerp',
    'Rotterdam',
    'Lyon',
    'Amsterdam',
    'Marseille',
    'Bergen',
    'Geneva',
    'São Tomé',
    'Düsseldorf',
    'Cologne',
    'Cannes',
    'Nice',
    'Monaco',
    'Bern',
    'Abuja',
    'Enugu',
    'Turin',
    'Strasbourg',
    'Kano',
    'Zürich',
    'Frankfurt',
    'Malabo',
    'Stuttgart',
    'Milan',
    'Libreville',
    'Vaduz',
    'Douala',
    'Hanover',
    'Hamburg',
    'Tunis',
    'Aarhus',
    'Oslo',
    'Innsbruck',
    'Yaoundé',
    'Munich',
    'Gothenburg',
    'Leipzig',
    'Rome',
    'Copenhagen',
    'Malmö',
    'Salzburg',
    'Tripoli',
    'Luanda',
    'Berlin',
    'Dresden',
    'Naples',
    'Linz',
    'Prague',
    'Sabha',
    'Birkirkara',
    'Ljubljana',
    'Valletta',
    'N\'Djamena',
    'Brazzaville',
    'Kinshasa',
    'Graz',
    'Longyearbyen',
    'Zagreb',
    'Vienna',
    'Bratislava',
    'Stockholm',
    'Sarajevo',
    'Cape Town',
    'Bangui',
    'Budapest',
    'Podgorica',
    'Tirana',
    'Kraków',
    'Belgrade',
    'Kaliningrad',
    'Warsaw',
    'Pristina',
    'Skopje',
    'Sofia',
    'Athens',
    'Tampere',
    'Lviv',
    'Riga',
    'Espoo',
    'Tallinn',
    'Helsinki',
    'Vilnius',
    'Port Elizabeth',
    'Livingstone',
    'Gaborone',
    'Bucharest',
    'Bloemfontein',
    'Tartu',
    'Lubumbashi',
    'Maseru',
    'Francistown',
    'Minsk',
    'Johannesburg',
    'Pretoria',
    'Lusaka',
    'Ndola',
    'Bulawayo',
    'Istanbul',
    'Bursa',
    'Bujumbura',
    'Tiraspol',
    'Alexandria',
    'Kigali',
    'Saint Petersburg',
    'Odessa',
    'Harare',
    'Durban',
    'Mbabane',
    'Lobamba',
    'Cairo',
    'Manzini',
    'Port Said',
    'Konya',
    'Omdurman',
    'Khartoum',
    'Suez',
    'Maputo',
    'Luxor',
    'Ankara',
    'Mwanza',
    'Murmansk',
    'Nicosia',
    'Lilongwe',
    'Simferopol',
    'Gaza',
    'Mersin',
    'Tel Aviv',
    'Blantyre',
    'Jerusalem',
    'Adana',
    'Beirut',
    'Dodoma',
    'Amman',
    'Damascus',
    'Kharkiv',
    'Nairobi',
    'Gaziantep',
    'Moscow',
    'Addis Ababa',
    'Asmara',
    'Jeddah',
    'Zanzibar City',
    'Medina',
    'Mecca',
    'Sukhumi',
    'Moroni',
    'Baghdad',
    'Hargeisa',
    'Yerevan',
    'Tbilisi',
    'Mamoudzou',
    'Mogadishu',
    'Tabriz',
    'Riyadh',
    'Stepanakert',
    'Antananarivo',
    'Basra',
    'Baku',
    'Dammam',
    'Samara',
    'Manama',
    'Tehran',
    'Doha',
    'Abu Dhabi',
    'Dubai',
    'Saint-Denis',
    'Perm',
    'Port Louis',
    'Ashgabat',
    'Muscat',
    'Nukus',
    'Mashhad',
    'Yekaterinburg',
    'Kandahar',
    'Karachi',
    'Hyderabad',
    'Dushanbe',
    'Kabul',
    'Tashkent',
    'Astana',
    'Multan',
    'Peshawar',
    'Namangan',
    'Ahmedabad',
    'Mumbai',
    'Surat',
    'Faisalabad',
    'Rawalpindi',
    'Islamabad',
    'Omsk',
    'Pune',
    'Lahore',
    'Bishkek',
    'Srinagar',
    'Amritsar',
    'Jaipur',
    'Ludhiana',
    'Almaty',
    'New Delhi',
    'Bangalore',
    'Nagpur',
    'Colombo',
    'Chennai',
    'Kanpur',
    'Kandy',
    'Lucknow',
    'Batticaloa',
    'Novosibirsk',
    'Patna',
    'Kathmandu',
    'Ürümqi',
    'Norilsk',
    'Gangtok',
    'Shigatse',
    'Thimphu',
    'Dhaka',
    'Lhasa',
    'Agartala',
    'Guwahati',
    'Chittagong',
    'Shillong',
    'Port Blair',
    'Dibrugarh',
    'Banda Aceh',
    'Yangon',
    'Medan',
    'Chiang Mai',
    'Surat Thani',
    'Padang',
    'Hat Yai',
    'Bangkok',
    'Pattaya',
    'Ipoh',
    'Pekanbaru',
    'Bratsk',
    'Kuala Lumpur',
    'Xining',
    'Nakhon Ratchasima',
    'Kota Bharu',
    'Vientiane',
    'Kunming',
    'Udon Thani',
    'Johor Bahru',
    'Lanzhou',
    'Singapore',
    'Siem Reap',
    'Chengdu',
    'Palembang',
    'Phnom Penh',
    'Hanoi',
    'Chongqing',
    'Hai Phong',
    'Ho Chi Minh City',
    'Jakarta',
    'Bogor',
    'Ulan Bator',
    'Bandung',
    'Da Nang',
    'Nanning',
    'Pontianak',
    'Kuching',
    'Semarang',
    'Taiyuan',
    'Malang',
    'Surabaya',
    'Guangzhou',
    'Macau',
    'Zhengzhou',
    'Dongguan',
    'Miri',
    'Shenzhen',
    'Hong Kong',
    'Wuhan',
    'Handan',
    'Shijiazhuang',
    'Bandar Seri Begawan',
    'Denpasar',
    'Mandurah',
    'Perth',
    'Kota Kinabalu',
    'Beijing',
    'Balikpapan',
    'Jinan',
    'Tianjin',
    'Port Hedland',
    'Nanjing',
    'Makassar',
    'Hangzhou',
    'Kaohsiung',
    'Qingdao',
    'Taichung',
    'Manila',
    'Quezon City',
    'Shanghai',
    'Taipei',
    'Dalian',
    'Iloilo City',
    'Zamboanga City',
    'Shenyang',
    'Tagbilaran',
    'Cebu City',
    'Changchun',
    'Dili',
    'Pyongyang',
    'Davao City',
    'Kaesong',
    'Harbin',
    'Incheon',
    'Seoul',
    'Wonsan',
    'Okinawa',
    'Ambon',
    'Daegu',
    'Busan',
    'Yakutsk',
    'Chongjin',
    'Fukuoka',
    'Darwin',
    'Vladivostok',
    'Hiroshima',
    'Melekeok',
    'Kobe',
    'Osaka',
    'Kyoto',
    'Nagoya',
    'Adelaide',
    'Yokohama',
    'Kawasaki',
    'Jayapura',
    'Sapporo',
    'Geelong',
    'Hagåtña',
    'Dededo',
    'Melbourne',
    'Saipan',
    'Cairns',
    'Townsville',
    'Port Moresby',
    'Hobart',
    'Canberra',
    'Rockhampton',
    'Magadan',
    'Wollongong',
    'Sydney',
    'Newcastle',
    'Weno',
    'Brisbane',
    'Gold Coast',
    'Palikir',
    'Honiara',
    'Nouméa',
    'Invercargill',
    'Dunedin',
    'Majuro',
    'Christchurch',
    'Wellington',
    'Auckland',
    'Suva',
    'Funafuti',
    'Labasa',
    'Nukuʻalofa',
    'Rabi Island',
    'St. Louis',
    'Santiago de Cuba',
    'New York City',
    'Port-au-Prince',
    'San Carlos de Bariloche',
    'Quebec City',
    'City of Halifax',
    'Santa Cruz de la Sierra',
    'St. George\'s',
    'Port of Spain',
    'Fort-de-France',
    'Ciudad del Este',
    'São José dos Campos',
    'Rio de Janeiro',
    'Horta (Azores)',
    'Angra do Heroísmo',
    'Santa Cruz de Tenerife',
    'Las Palmas de Gran Canaria',
    'Cork',
    'Marrakech',
    'Andorra la Vella',
    'Luxembourg',
    'City of San Marino',
    'Vatican City',
    'Split',
    'Gdańsk',
    'Thessaloniki',
    'İzmir',
    'Chişinău',
    'Kiev',
    'Dar es Salaam',
    'Djibouti',
    'Tskhinvali',
    'Sana\'a',
    'Nizhny Novgorod',
    'Arbil',
    'Kuwait City',
    'Malé',
    'Sri Jayawardenapura-Kotte',
    'Kolkata',
    'Naypyidaw',
    'Phuket'
  ]
