<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-row class="d-flex justify-center">
      <v-col
        :cols="12"
      >
        <v-text-field
          v-model="answer"
          outlined
          class="centered-input"
          hint="Write your answer and send it!"
          autofocus
          rounded
          :disabled="isReady"
        />
      </v-col>
    </v-row>
    <GameToggleButton
      class="mt-5"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'

export default {
  name: 'TheCaseGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  data () {
    return {
      title: 'The Case',
      subtitle: 'Solve the first riddle and answer below',
      databaseString: 'v1',
      isSending: false,
      isReady: false,
      notReadyLabel: 'Final answer?',
      readyLabel: 'Answer locked!',
      answer: ''
    }
  },
  methods: {
    async onToggledClick (toggled) {
      this.isReady = toggled
      this.isSending = true
      if (this.isReady) {
        const points = await this.correctAnswers(this.answer)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, this.answer, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answer) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      return facts.answer === answer ? 1 : 0
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    this.answer = this.previousValues
  }
}
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-weight: bold;
    font-size: large;
  }

</style>
