<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-text-field
        v-model="answer"
        outlined
        class="centered-input"
        autofocus
        type="number"
        :rules="[allowedNumbers]"
        rounded
        :disabled="isReady"
        @update:error="(v) => disableButton = v"
    />
    <GameToggleButton
      class="mt-5"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :notReadyLabel="notReadyLabel"
      :disabled="disableButton"
      :loading="isSending"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'

export default {
  name: 'TiebreakerGame',
  props: [
    'enteredPreviously',
    'previousAnswer'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  data () {
    return {
      title: 'The Tiebreaker',
      subtitle: 'Guess the number!',
      databaseString: 'v1',
      isReady: false,
      isSending: false,

      disableButton: false,
      allowedNumbers: (v) => (v >= 0 && v <= 100 && v !== '') || 'Pick a number between 0 and 100',
      notReadyLabel: 'Are you sure?',
      readyLabel: 'Alrighty then!',
      answer: ''
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const points = await this.correctAnswers(this.answer)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, this.answer, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answer) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const fact = snapshot.val()
      let answerValue = 0
      if (typeof (answer) === 'string') {
        answerValue = parseInt(answer)
      } else {
        answerValue = answer
      }

      // Get different scores automatically if you are close or not
      if (answerValue === fact) {
        return 10
      }
      if (answerValue >= fact - 3 && answerValue <= fact + 3) {
        return 9
      }
      if (answerValue >= fact - 5 && answerValue <= fact + 5) {
        return 8
      }
      if (answerValue >= fact - 8 && answerValue <= fact + 8) {
        return 7
      }
      if (answerValue >= fact - 10 && answerValue <= fact + 10) {
        return 6
      }
      if (answerValue >= fact - 15 && answerValue <= fact + 15) {
        return 5
      }
      if (answerValue >= fact - 20 && answerValue <= fact + 20) {
        return 4
      }
      if (answerValue >= fact - 40 && answerValue <= fact + 40) {
        return 3
      }
      if (answerValue >= fact - 50 && answerValue <= fact + 50) {
        return 2
      }
      if (answerValue >= fact - 60 && answerValue <= fact + 60) {
        return 1
      }
      return 0
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    this.answer = this.previousAnswer
  }
}
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center;
    margin-left: 50px;
    letter-spacing: 5px;
    font-size: larger;
  }

</style>
