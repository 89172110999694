<template>
  <v-app>
    <v-main>
        <v-container
          fill-height
          class="pa-0 justify-center align-center"
          fluid
          :style="{'background-color': '#B0BEC5'}"
          @click="clickedBackground()"
        >
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="8"
         >
          <transition
            @before-enter="animResetOpacity"
            @enter="animTitleEnter"
            v-bind:css="false"
          >
            <v-row
              v-if="showTitle"
              class="d-flex flex-column flex-nowrap"
            >
            <v-card
              class="pa-5"
              rounded="xl"
              elevation="15"
              ref="title-score"
            >
              <v-row align="center">
                <v-col
                  cols="4"
                >
                  <v-img
                    eager
                    contain
                    :src="activeGame.imageURL"
                  />
                </v-col>
                <v-divider vertical inset class="ma-5" style="background-color : #441468 "/>
                <v-col class="ma-5">
                  <v-row>
                    <p :class="
                      [{ 'display-3' : $vuetify.breakpoint.xl },
                      { 'display-2' : $vuetify.breakpoint.sm || $vuetify.breakpoint.md || $vuetify.breakpoint.lg},
                      { 'display-1' : $vuetify.breakpoint.xs },
                      'text--primary']"
                    >
                      The Highscore
                    </p>
                  </v-row>
                  <v-row>
                    <p :class="
                      [{ 'display-1' : $vuetify.breakpoint.xl },
                      { 'text-lg-h5' : $vuetify.breakpoint.sm || $vuetify.breakpoint.md || $vuetify.breakpoint.lg},
                      { 'text-lg-h6' : $vuetify.breakpoint.xs },
                      'text--secondary']">
                      {{ activeGame.title }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            </v-row>
          </transition>
          <transition
            @before-enter="animResetOpacity"
            @enter="animDividerEnter"
            v-bind:css="false"
          >
            <v-row
              v-if="showTitle"
              class="d-flex flex-column"
            >
              <v-card class="py-3 mx-3 my-10 elevation-5" rounded="xl" :style="{'background-color': activeGame.color}"/>
            </v-row>
          </transition>
          <v-row
            class="mx-15 d-flex flex-column flex-nowrap"
          >
          <transition
            @before-enter="animResetOpacity"
            @enter="animScoreboardEnter"
            v-bind:css="false"
          >
            <v-card
              class="pa-5"
              rounded="xl"
              elevation="15"
              v-if="showScore"
          >
            <v-simple-table
              class="px-2"
            >
              <template v-slot:default>
                <tbody
                  is="transition-group"
                  @before-enter="animScoreBoardItemBeforeEnter"
                  @enter="animScoreBoardItemEnter"
                  v-bind:css="false"
                  class="scoreboard-row-transform"
                >
                  <tr
                    v-for="score in scoresOnBoard"
                    :key="score.place"
                    :show="score.show"
                    :ref="`row` + score.place"
                    class="scoreboard-row-transform"
                  >
                    <td
                      class="py-5"
                      :ref="`chip` + score.place"
                    >
                      <v-chip
                        :ripple="false"
                        color="yellow accent-4"
                        v-if="score.place === 1"
                        style="vertical-align: baseline; border: groove"
                        x-large
                      >
                        <span :class="[textChip, 'font-weight-bold']">{{ score.place }}</span>
                      </v-chip>
                      <v-chip
                        :ripple="false"
                        color="blue-grey lighten-3"
                        v-else-if="score.place === 2"
                        style="vertical-align: baseline; border: groove"
                        x-large
                      >
                        <span :class ="[textChip]">{{ score.place }}</span>
                      </v-chip>
                      <v-chip
                        :ripple="false"
                        color="amber accent-3"
                        v-else-if="score.place === 3"
                        style="vertical-align: baseline; border: groove"
                        x-large
                      >
                        <span :class ="[textChip]">{{ score.place }}</span>
                      </v-chip>
                      <v-chip
                        :ripple="false"
                        v-else
                        style="vertical-align: baseline; border: groove"
                        x-large
                        outlined
                      >
                        <span :class ="[textChip]">{{ score.place }}</span>
                      </v-chip>
                    </td>
                      <td
                        class="text-left"
                        :ref="`name` + score.place"
                      >
                        <span
                          :class="[textTeam,'font-weight-black']"
                          v-if="score.place === 1"
                        >{{ score.name }}</span>
                        <span
                          :class="[textTeam,'font-weight-normal']"
                          v-else-if="score.place === 2"
                        >{{ score.name }}</span>
                        <span
                          :class="[textTeam]"
                          v-else
                        >{{ score.name }}</span>
                      </td>
                    <td
                      class="text-right"
                      :ref="`score` + score.place"
                    >
                        <span
                          :class="[textScore, 'fill-height', 'font-weight-bold']"
                          v-if="score.place === 1"
                        >{{ score.points }}</span>
                        <span
                          :class="[textScore, 'fill-height']"
                          v-else
                        >{{ score.points }}</span>
                        <span :class="[textOther, 'fill-height', 'ml-5']">points</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-card>
          </transition>
          </v-row>
        </v-col>
        </v-container>
        <!-- <v-container
          fluid
          :style="{'background-color': '#BDBDBD'}"
        >
          <v-btn
          class="ml-5 mb-5"
          width="100px"
          height="50px"
          outlined
          >
          Show
          </v-btn>
        </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Velocity from 'velocity-animate'

export default {
  name: 'HighScore',
  data () {
    return {
      showTitle: false,
      showScore: false,
      logotypeImage: undefined,
      scoresOnBoard: [],

      // Score variables
      sequentialPositionShift: undefined,
      sequentialScoreList: undefined,
      lumpedScoreList: undefined,
      sequentialScoreLimit: 5

    }
  },
  computed: {
    allscores () {
      let totalScores = []
      const scoreList = this.gameScores(undefined)

      for (const i in scoreList) { // Every game (i)
        for (const j in scoreList[i]) { // j team in i game
          const teamScore = scoreList[i][j]

          // Now we loop through our array of objects and find the correct spot to add the points
          let found = false
          let t = 0

          while (!found) {
            // We haven't found it
            if (t === totalScores.length) {
              totalScores.push({ name: teamScore.name, points: teamScore.points })
              found = true
            } else {
              if (totalScores[t].name === teamScore.name) {
                totalScores[t].points += teamScore.points
                found = true
              } else {
                t++
              }
            }
          }
        }
      }
      totalScores = totalScores.sort(function (a, b) {
        return a.points - b.points
      })
      return totalScores.reverse()
    },
    textChip () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'h2'
        case 'sm':
        case 'md':
        case 'lg': return ['headline', 'ma-4']
        case 'xl': return ['display-1', 'ma-4']
        default: return 'h2'
      }
    },
    textTeam () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'h2'
        case 'sm':
        case 'md':
        case 'lg': return 'headline'
        case 'xl': return 'display-1'
        default: return 'h2'
      }
    },
    textScore () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'h2'
        case 'sm':
        case 'md':
        case 'lg': return 'display-1'
        case 'xl': return 'display-2'
        default: return 'h2'
      }
    },
    textOther () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'h2'
        case 'sm':
        case 'md':
        case 'lg': return ['h3']
        case 'xl': return 'headline'
        default: return 'h2'
      }
    },
    ...mapGetters('admin', [
      'activeGame',
      'gameScores'
    ])
  },
  methods: {
    clickedBackground () {
      // First we show thetitle, then we display the scores
      if (!this.showTitle) {
        this.showTitle = true
      } else if (this.showTitle && !this.showScore) {
        this.showScore = true
        this.sequentialScoreList = this.allscores.slice()
        if (this.allscores.length > this.sequentialScoreLimit) {
          // We have more scores and must show the rest as a huge lump at the end
          this.lumpedScoreList = this.sequentialScoreList.splice(this.sequentialScoreLimit)
          this.sequentialPositionShift = this.sequentialScoreList.length
        }
        this.showResults()
      } else {
        this.showResults()
      }
    },
    showResults () {
      // Show scores from Top 5 and then show everyone
      if (this.sequentialScoreList.length > 0) {
        const place = this.sequentialScoreList.length
        const score = this.sequentialScoreList.pop()
        this.scoresOnBoard.unshift({ name: score.name, points: score.points, place: place })
        this.latestPlaceAdded = place
      } else if (this.lumpedScoreList.length > 0) {
        let score
        let place = 0
        while (this.lumpedScoreList.length > 0) {
          score = this.lumpedScoreList.shift()
          place += 1
          this.scoresOnBoard.push({ name: score.name, points: score.points, place: this.sequentialPositionShift + place })
          this.latestPlaceAdded = this.sequentialPositionShift + place
        }
      } else {
        this.animScrollPage()
      }
    },
    animConfettiSpray () {
      const self = this
      this.$confetti.start({ particles: [{ type: 'rect' }, { type: 'circle' }], defaultColors: ['#BDBDBD', '#388E3C', this.activeGame.color] })
      setTimeout(() => { self.$confetti.stop() }, 5000)
    },
    animScrollPage () {
      Velocity(this.$refs['row' + this.latestPlaceAdded], 'scroll', { duration: 500 * this.allscores.length, easing: 'ease-in', loop: 4 })
      // Velocity(this.$refs['title-score'], 'scroll', { duration: 300 * this.allscores.length, easing: 'ease-in' })
    },
    animScoreBoardItemBeforeEnter (el) {
      el.style.opacity = 0
      Velocity(el, { scaleY: 0 }, { duration: 0 })
    },
    animScoreBoardItemEnter (el, done) {
      Velocity(el, { opacity: 1 }, { duration: 800, queue: false })
      if (this.latestPlaceAdded === 1) {
        Velocity(this.$refs['row' + this.latestPlaceAdded], { scaleY: 1 }, { duration: 1000, complete: this.animConfettiSpray() })
        Velocity(this.$refs['row' + this.latestPlaceAdded], { scale: 1.03 }, { duration: 300, loop: true })
      } else if (this.latestPlaceAdded < 4) {
        Velocity(this.$refs['row' + this.latestPlaceAdded], { scaleY: 1 }, { duration: 600 })
        Velocity(this.$refs['row' + this.latestPlaceAdded], { scale: 1.03 }, { duration: 300, loop: 1, complete: done })
      } else {
        Velocity(el, { scaleY: 1 }, { duration: 600, complete: done })
      }
    },
    animResetOpacity (el) {
      el.style.opacity = 0
    },
    animTitleEnter (el, done) {
      Velocity(el, { translateY: -50 })
      Velocity(el, { opacity: 1, translateY: 0 }, { duration: 800, complete: done })
    },
    animDividerEnter (el, done) {
      Velocity(el, { translateY: -25 })
      Velocity(el, { opacity: 1, translateY: 0 }, { duration: 1000, complete: done })
    },
    animScoreboardBeforeEnter (el) {
      el.style.opacity = 0
    },
    animScoreboardEnter (el, done) {
      Velocity(el, { opacity: 1 }, { duration: 1000, queue: false }, 'easeInSine')
      Velocity(el, 'slideDown', { duration: 1200, complete: done })
    },
    ...mapActions('admin', [
      'listenToActiveGameGroups',
      'stopListeningToActiveGameGroups'
    ])
  },
  mounted () {
    this.listenToActiveGameGroups()
    this.scoresOnBoard = []
  },
  destroyed () {
    this.stopListeningToActiveGameGroups()
  }

}
</script>
<style scoped>
.scoreboard-row-transform {
  transform-origin: bottom;
}
.scoreboard-enter-active, .scoreboard-leave-active {
  transition: all 1s;
}
.scoreboard-enter, .scoreboard-leave-to {
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0)
}
.scoreboard-enter-to {
  opacity: 1;
  transform: scaleY(1)
}
.scoreboard-leave-active {
  position: absolute;
}
</style>
