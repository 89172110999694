<template>
    <CardBackground>
      <v-card-title class="d-flex justify-center">{{ title }}</v-card-title>
      <template v-if="subtitle !== null || subtitle.length > 0">
        <v-card-subtitle class="d-flex justify-center mb-5 text-center">{{ subtitle }}</v-card-subtitle>
      </template>
      <v-divider class="mb-10 elevation-1"></v-divider>
      <slot />
    </CardBackground>
</template>

<script>
import CardBackground from '@/components/CardBackground.vue'

export default {
  name: 'GameBackground',
  components: {
    CardBackground
  },
  props: [
    'title',
    'subtitle'
  ],
  data () {
    return {
      // Do we need any data now?
    }
  }
}
</script>
