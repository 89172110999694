import Vue from 'vue'
import VueRouter from 'vue-router'
import GameLibrary from '../views/GameLibrary.vue'
import CreateNew from '../views/CreateNew.vue'
import Supervise from '../views/Supervise.vue'
import Game from '@/views/Game.vue'
import HighScore from '@/views/HighScore.vue'
import Login from '@/views/Login.vue'
import * as firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Client',
    component: Login,
    meta: {
      title: 'LevelUp by Utmaningarnas Hus'
    },
    props: {
      client: true
    }
  },
  {
    path: '/game',
    name: 'ClientGame',
    component: Game,
    meta: {
      title: 'LevelUp by Utmaningarnas Hus'
    },
    beforeEnter: function (to, from, next) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          // User is not signed in and forced themselves in the game site
          // Checking if user actually is a playing user
          next('/')
        } else {
          // console.log('Continuing through route')
          next()
        }
      })
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Login,
    meta: {
      title: 'Admin Login - LevelUp'
    },
    props: {
      client: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: 'Dashboard - LevelUp'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [
      {
        path: 'library',
        name: 'GameLibrary',
        component: GameLibrary,
        props: true
      },
      {
        path: 'create',
        name: 'CreateNew',
        component: CreateNew
      },
      {
        path: 'supervise',
        name: 'Supervise',
        component: Supervise
      }
    ],
    component: () => import(/* webpackChunkName: "admin" */ '../views/Dashboard.vue'),
    beforeEnter: function (to, from, next) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          // User is not signed in and forced themselves into Dashboard site
          // console.log('Cannot access dashboard, please login first')
          next('/admin')
        } else {
          // console.log('Exiting through route')
          next()
        }
      })
    }
  },
  {
    path: '/highscore',
    name: 'Highscore',
    component: HighScore,
    meta: {
      title: 'Highscore - LevelUp'
    },
    beforeEnter: function (to, from, next) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          // User is not signed in and forced themselves
          // console.log('Cannot access dashboard, please login first')
          next('/')
        } else {
          // console.log('Exiting through route')
          next()
        }
      })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
