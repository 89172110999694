<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-row
        class="d-flex justify-center"
        v-for="n in nbrofQuestions/2"
        :key="n"
    >
      <v-col
        class="d-flex justify-center"
        :cols="cols"
        v-for="d in 2"
        :key="d"
      >
        <v-row
          class="d-flex justify-center"
          :cols="12"
        >
          <v-container
            class="px-5 text-h6 gray-text"
            elevation="2"
          >
              {{ 2*n + d-2 + ':'}}
          </v-container>
          <v-card
            class="mb-5 mx-5"
            outlined
            no-gutters
          >
            <v-overlay
              absolute
                :value="false"
            />
            <v-btn-toggle
              v-model="answers[2*n+d-3]"
            >
              <v-row
                no-gutters
                v-for="c in 2"
                :key="c"
              >
                <v-col
                  cols="12"
                  class="d-flex child-flex"
                  v-for="x in 2"
                  :key="x"
                >
                  <v-btn
                    class="py-10 misfit-button"
                    elevation="0"
                    outlined
                    :disabled="isReady"
                  >
                    <v-icon
                      x-large
                      :style="{'opacity': answers[2*n+d-3] === 2*c+x-3 ? 1.0 : 0.0}"
                    >mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-btn-toggle>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <GameToggleButton
      class="mt-5"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'

export default {
  name: 'MisfitGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  computed: {
    cols () {
      if (this.$vuetify.breakpoint.width < 440) {
        return 12
      } else {
        return 6
      }
    }
  },
  data () {
    return {
      title: 'Misfit',
      subtitle: 'Pick the odd one out!',
      databaseString: 'v1',

      isReady: false,
      isSending: false,
      notReadyLabel: 'Final answer?',
      readyLabel: 'Answer locked!',
      answers: [],
      nbrofQuestions: 10,
      boxCounter: 0
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const points = await this.correctAnswers(this.answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, this.answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0

      for (let i = 0; i < answers.length; i++) {
        if (facts[i] === answers[i]) {
          matches += 1
        }
      }
      return matches
    }
  },
  mounted () {
    if (this.enteredPreviously !== undefined) {
      this.isReady = this.enteredPreviously
    }
    if (this.previousValues !== undefined) {
      this.answers = this.previousValues
    } else {
      this.answers = Array(this.nbrofQuestions)
    }
  }
}
</script>

<style scoped>
  .gray-text {
    color: gray;
    font-weight: bold;
  }
  .misfit-button {
    border-radius: 0px;
    outline-color:black
  }

</style>
