<template>
  <v-card
    color="primary"
    dark
    class="ma-5"
    rounded="xl"
    outlined
    elevation="3"
  >
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="1"
        class="ml-5"
      >
        <v-icon dark>
          mdi-account-multiple
        </v-icon>
      </v-col>
      <v-col
        class="ml-2 text-wrap"
        cols="6"
      >
        <v-card-text class="pl-0">
          <b>{{ teamName }}</b>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text
          class="text-right text-no-wrap"
          v-if="!showScore"
          key="question"
        >
          Question <b>{{ questionNumber }}</b>
        </v-card-text>
        <v-card-text
          class="text-right"
          v-else
          key="score"
        >
          <b>{{ currentScore }}</b> points
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'TeamStatus',
  props: [
    'teamName',
    'questionNumber',
    'scores',
    'teamCode',
    'showScore'
  ],
  computed: {
    currentScore () {
      if (this.scores === undefined) {
        return 0
      } else {
        for (const i in this.scores) {
          if (this.scores[i].code === this.teamCode) {
            return this.scores[i].points
          }
        }
        return 0
      }
    }
  }

}
</script>
