<template>
  <v-card
    elevation="20"
    rounded="xl"
  >
    <v-toolbar :color="color"
      dark
      extended
      elevation="20"
      prominent
    >
      <v-spacer/>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer/>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :href="source"
            icon
            large
            target="_blank"
            v-on="on"
          >
            <v-icon>mdi-code-tags</v-icon>
          </v-btn>
        </template>
        <span>Source</span>
      </v-tooltip> -->
    </v-toolbar>
    <v-card-text class="text-center mb-n5 mt-5">
      Enter your 'Host Login' information below
    </v-card-text>
    <v-card-text class="my-5">
      <v-form>
        <v-text-field
          label="E-mail"
          v-model = "email"
          prepend-inner-icon="mdi-account"
          type="text"
          outlined
          rounded
          :error-messages="errormessage"
          color="primary"
          autofocus
        ></v-text-field>

        <v-text-field
          id="password"
          label="Password"
          v-model = "password"
          prepend-inner-icon="mdi-lock"
          type="password"
          outlined
          rounded
          color="primary"
          :error="errorPassword"
          @keyup.enter="buttonClicked"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center pt-5 pb-10 px-10">
      <v-btn color="green darken-2"
        rounded
        block
        x-large
        :disabled="email == '' "
        v-on:click="buttonClicked"
        :loading="buttonLoading"
        class="white--text"
      >Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'LoginAdmin',
  data () {
    return {
      email: '',
      password: '',
      errormessage: '',
      title: 'Admin Dashboard',
      color: 'green darken-2',
      buttonLoading: false,
      errorPassword: false
    }
  },
  methods: {
    setErrorMessage (text) {
      this.errormessage = text
      if (text.length > 0) {
        this.title = 'Oh nooo'
        this.color = 'error'
        this.errorPassword = true
      } else {
        this.title = 'Admin Dashboard'
        this.color = 'green darken-2'
        this.errorPassword = false
      }
    },
    async buttonClicked () {
      this.buttonLoading = true
      try {
        await this.$store.dispatch('admin/login', {
          email: this.email,
          password: this.password
        })
      } catch (ex) {
        this.setErrorMessage('Wrong login information')
        this.buttonLoading = false
      }
    }
  },
  watch: {
    email: function (val) {
      this.setErrorMessage('')
    },
    password: function (val) {
      this.setErrorMessage('')
    }
  },
  computed: {
    backgroundStyle () {
      return {
        backgroundImage: `url(${this.background})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundBlendMode: 'multiply'
      }
    }
  }
}

</script>
