<template>
    <v-card
      elevation="3"
      class="ma-5 pa-10"
      rounded="xl"
      outlined
    >
      <v-card-title class="d-flex justify-center">Who are you?</v-card-title>
      <v-card-subtitle class="d-flex justify-center mb-5 text-center">Choose a new name or select the current one</v-card-subtitle>
      <v-divider class="mb-10 elevation-1"></v-divider>
      <v-text-field
          outlined
          :rules="[max15chars,min1chars]"
          label="Enter your team name"
          rounded
          v-model="newName"
          :disabled="isReady"
          @update:error="(error) => hasError = error"
      />
      <v-card-text
        class="d-inline-flex justify-center text-center"
      >
        {{ isReady ? bodyTextReady : bodyTextNotReady }}
      </v-card-text>
      <GameToggleButton
        class="mt-5"
        :readyLabel="buttonReadyLabel"
        :notReadyLabel="buttonNotReadyLabel"
        :selected="loggedInPreviously"
        :loading="isSending"
        :disabled="hasError"
        @toggleClick="onToggledClick"
      />
    </v-card>
</template>

<script>
import GameToggleButton from '@/components/GameToggleButton'

export default {
  name: 'TeamSettings',
  components: {
    GameToggleButton
  },
  props: [
    'teamName',
    'loggedInPreviously'
  ],
  data () {
    return {
      max15chars: (v) => v.length <= 15 || 'Too many characters!!',
      min1chars: (v) => v.trim().length > 0 || 'Can\'t leave it empty',
      buttonColor: 'gray',
      buttonCurrentLabel: '',
      buttonNotReadyLabel: 'Are you ready?',
      buttonReadyLabel: 'Waiting for host...',
      bodyTextNotReady: 'Click the button to indicate that you are ready!',
      bodyTextReady: 'Nice, now we wait for the game to start!',
      newName: '',
      isSending: false,
      hasError: false,
      isReady: false
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isSending = true
      this.isReady = value
      await this.$emit('teamSettingsToggled', this.newName.trim(), value)
      this.isSending = false
    }
  },
  mounted () {
    this.newName = this.teamName
    this.isReady = this.loggedInPreviously
  }
}
</script>
