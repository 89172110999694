<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-row
      class="d-flex"
      v-for="(item, n) in items"
      :key="n"
    >
      <v-col
        :cols="8"
        class="pb-10 pl-10"
      >
        <v-img
          :src="'/gameassets/ShapeUp/' + item.picture"
          contain
          position="left center"
          max-width="300px"
          aspect-ratio="1"
        />
      </v-col>
      <v-col
        :cols="4"
      >
        <v-row
          v-for="(field, d) in item.fields"
          :key="d"
          class="pr-5"
        >
          <v-text-field
            outlined
            class='centered-input mt-n3'
            type="number"
            rounded
            :label="field"
            v-model="item.answers[d]"
            :disabled="isReady"
          />
        </v-row>
      </v-col>
      <v-divider
        v-if="n !== items.length-1"
        class="mx-10 mb-15"
      />
    </v-row>
    <GameToggleButton
      class="mt-10"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
// Doing a double equals here because we compare strings with integers
/* eslint-disable eqeqeq */
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'

export default {
  name: 'ShapeUpGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  data () {
    return {
      title: 'Shape Up',
      subtitle: 'Make sense of the shapes',
      databaseString: 'v1',

      isReady: false,
      isSending: false,
      notReadyLabel: 'Shapes complete?',
      readyLabel: 'Shapely done!',
      items: [
        {
          picture: 'T.png',
          fields: ['A', 'B', 'C', 'D'],
          answers: ['', '', '', '']
        },
        {
          picture: 'H.png',
          fields: ['E', 'F', 'G', 'H', 'I', 'J'],
          answers: ['', '', '', '', '', '']
        },
        {
          picture: 'F.png',
          fields: ['K', 'L', 'M', 'N', 'O'],
          answers: ['', '', '', '', '']
        },
        {
          picture: 'K.png',
          fields: ['P', 'Q', 'R', 'T', 'S'],
          answers: ['', '', '', '', '']
        }
      ]
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const answers = {}
        answers.T = this.items[0].answers
        answers.H = this.items[1].answers
        answers.F = this.items[2].answers
        answers.K = this.items[3].answers
        const points = await this.correctAnswers(answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (items) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0
      // We check "T" first
      for (let i = 0; i < items.T.length; i++) {
        if (facts.T[i] == items.T[i]) {
          matches += 0.5
        }
      }
      // Next "H", here we have two choices, the database stores a string containing options. we split the string and check both!
      for (let i = 0; i < items.H.length; i++) {
        const values = facts.H[i].split('/')
        if (values[0] == items.H[i] || values[1] == items.H[i]) {
          matches += 0.5
        }
      }
      // Next "F"
      for (let i = 0; i < items.F.length; i++) {
        if (facts.F[i] == items.F[i]) {
          matches += 0.5
        }
      }
      // Next "K"
      for (let i = 0; i < items.K.length; i++) {
        if (facts.K[i] == items.K[i]) {
          matches += 0.5
        }
      }

      return matches
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    if (this.previousValues !== undefined) {
      this.items[0].answers = this.previousValues.T
      this.items[1].answers = this.previousValues.H
      this.items[2].answers = this.previousValues.F
      this.items[3].answers = this.previousValues.K
    }
  }
}
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center;
    font-weight: bold;
    font-size: large;
  }

</style>
