<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @click:outside="$emit('update:dialog', $event.target.value)"
      max-width="400"
    >
      <v-card>
        <v-card-title
          class="headline pa-5"
        >
          Lagkoder som är kvar
        </v-card-title>
        <v-card-text v-show="teams.length === 0">
          <b>Alla koder upptagna, sorry!</b><br>Pröva med en refresh på sidan om du vet att detta är fel
        </v-card-text>
        <v-simple-table class="pa-5" v-show="teams.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Lagnamn
                </th>
                <th class="text-left">
                  Kod
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(team, index) in teams"
                :key="index"
              >
                <td>{{ team.name }}</td>
                <td>{{ team.code }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="$emit('update:dialog', $event.target.value); $emit('click')"
          >
            Tack
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'TeamsModal',
  props: [
    'dialog',
    'teams'
  ]
}
</script>
