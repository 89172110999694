<template>
  <div class="no-gutters">
    <v-fade-transition
      mode="out-in"
      @enter="(e1) => { $emit('game-enter', e1) }"
      @leave="(e1) => { $emit('game-leave', e1) }"
    >
      <component
        :previousValues="historicGameValue(scenario[currentQuestion].name)"
        :enteredPreviously="historicGameReady(scenario[currentQuestion].name)"
        :is="scenario[currentQuestion].name"
        :selected="historicGameReady"
        :title="scenario[currentQuestion].friendlyname"
        :loading="toggleButtonLoading"
        @gameReady="gameReadyClicked"
        @gameNotReady="gameNotReadyClicked"
      />
    </v-fade-transition>
  </div>
</template>

<script>
// General imports
import { mapGetters } from 'vuex'

// Game imports
import ShazamGame from '@/games/Shazam/Shazam'
import TasteBudsGame from '@/games/TasteBuds/TasteBudsDigital'
import PhonyEyeGame from '@/games/PhonyEye/PhonyEye'
import MisfitGame from '@/games/Misfit/MisfitAlternative'
import DigitalEightGame from '@/games/DigitalEight/DigitalEight'
import HollywoodGame from '@/games/Hollywood/Hollywood'
import MemoryGame from '@/games/Memory/Memory'
import ThemeSongGame from '@/games/ThemeSong/ThemeSong'
import SlogansGame from '@/games/Slogans/Slogans'
import TheTouristGame from '@/games/TheTourist/TheTourist'

export default {
  name: 'TheDigitalScenario',
  components: {
    ShazamGame,
    TasteBudsGame,
    PhonyEyeGame,
    MisfitGame,
    DigitalEightGame,
    HollywoodGame,
    MemoryGame,
    ThemeSongGame,
    SlogansGame,
    TheTouristGame
  },
  computed: {
    ...mapGetters('client', [
      'gameStatus',
      'currentQuestion',
      'historicGameReady',
      'historicGameValue'
    ]),
    ...mapGetters([
      'scenarioGamesByComponentName'
    ])
  },
  data () {
    return {
      toggleButtonLoading: false,
      scenario: undefined
    }
  },
  methods: {
    async gameReadyClicked (componentName, gameVersion, answers, points) {
      await this.$emit('gameToggled', componentName, gameVersion, answers, points, true)
    },
    gameNotReadyClicked (componentName, gameVersion) {
      this.$emit('gameToggled', componentName, gameVersion, undefined, undefined, false)
    }
  },
  // Called before the first render!
  beforeMount () {
    this.scenario = this.scenarioGamesByComponentName(this.$options.name)
  }
}
</script>
