<template>
  <div class="no-gutters">
    <p>Vilka frågar ska vara med i "Memory"</p>
    <v-text-field
      label="Antal frågor"
      type="number"
      :rules="[gameMemoryQuestionRule]"
      v-model="gameMemoryQuestions"
      hint="Får vara en siffra mellan 1 och 10"
    />
    <template v-if="!!gameMemoryQuestions">
      <template v-for="(n,index) in parseInt(gameMemoryQuestions)">
        <v-expand-transition group :key="n">
          <v-row :key="n">
            <v-col>
              <v-select
                label="Typ"
                v-model="gameData[gameName][index].type"
                :items="gameMemoryTypes"
                @change="gameMemorySetType($event, index)"
                :rules="[(v) => !!v || 'Du måste välja en typ']"
                hint="Begränsar svarsalternativen"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Prefix"
                v-model="gameData[gameName][index].prefix"
                hint="Kan vara värdnamn som 'Anna'"
                :rules="[(v) => !!v || 'Du måste skriva ett prefix']"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Svar"
                :type="reactiveTypes[index]"
                :rules="[(v) => !!v || 'Du måste skriva ett svar']"
                v-model="gameData[gameName][index].answer"
                hint="Vad svaret ska vara?"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Suffix"
                v-model="gameData[gameName][index].suffix"
                hint="Skriv ett suffix, exempelvis 'years' eller 'cm', kan vara tomt"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MemoryModifications',
  props: [
    'gameData',
    'preData'
  ],
  data () {
    return {
      gameName: 'MemoryGame',
      gameMemoryQuestions: 5,
      gameMemoryQuestionRule: (v) => (v > 0 && v <= 10) || 'Måste vara en siffra mellan 0 och 10',
      gameMemoryTypes: ['Text', 'Time', 'Number'],
      reactiveTypes: ['Time', 'Text', 'Number', 'Text', 'Number']
    }
  },
  watch: {
    gameMemoryQuestions (val) {
      if (val < 0) {
        return
      }
      const newLength = val - this.gameData[this.gameName].length
      if (newLength > 0) {
        for (let i = 0; i < newLength; i++) {
          this.gameData[this.gameName].push({ prefix: '', suffix: '', type: '', answer: '' })
          this.reactiveTypes.push('text')
        }
      } else {
        for (let i = 0; i < Math.abs(newLength); i++) {
          this.gameData[this.gameName].pop()
          this.reactiveTypes.pop()
        }
      }
    }
  },
  methods: {
    gameMemorySetType (value, place) {
      switch (value) {
        // We have a seperate array called reactive types to induce reactivity when stuff changes etc.
        case 'Number':
          this.$set(this.reactiveTypes, place, 'number')
          return
        case 'Text':
          this.$set(this.reactiveTypes, place, 'text')
          return
        case 'Time':
          this.$set(this.reactiveTypes, place, 'time')
      }
    }
  },
  beforeMount () {
    // Add space for saving this data
    if (this.preData === 1) {
      this.gameData[this.gameName] = [
        { prefix: 'Woke up:', suffix: '', type: 'Time', answer: '06:27' },
        { prefix: 'Slowpoke:', suffix: '', type: 'Text', answer: 'Christian' },
        { prefix: 'Olle:', suffix: 'cm', type: 'Number', answer: '181' },
        { prefix: 'Lisa:', suffix: '', type: 'Text', answer: 'Guinea pig' },
        { prefix: 'We are:', suffix: 'years old', type: 'Number', answer: '16' }
      ]
    } else if (this.preData === 2) {
      this.gameData[this.gameName] = [
        { prefix: 'Breakfast at:', suffix: '', type: 'Time', answer: '07:30' },
        { prefix: 'Record:', suffix: 'tries', type: 'Number', answer: '15' },
        { prefix: 'Olles outfit:', suffix: '', type: 'Text', answer: 'Pink' },
        { prefix: 'Olles height:', suffix: 'cm', type: 'Number', answer: '181' },
        { prefix: 'Name:', suffix: '', type: 'Text', answer: 'Lisa' }
      ]
    } else {
      this.gameData[this.gameName] = [
        { prefix: '', suffix: '', type: 'Time', answer: '' },
        { prefix: '', suffix: '', type: 'Number', answer: '' },
        { prefix: '', suffix: '', type: 'Text', answer: '' },
        { prefix: '', suffix: '', type: 'Number', answer: '' },
        { prefix: '', suffix: '', type: 'Text', answer: '' }
      ]
    }
    for (const index in this.gameData[this.gameName]) {
      this.gameMemorySetType(this.gameData[this.gameName][index].type, index)
    }
  }
}
</script>
