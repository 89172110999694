<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-container
      class="d-flex justify-center"
    >
      <div class="body-2">Every <b><u>10 cm</u></b> gives you one level!</div>
    </v-container>
    <v-container
      class="d-flex justify-center"
    >
      <div class="subtitle-2 mb-5">Can we build it? Yes we can!</div>
    </v-container>
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'

export default {
  name: 'BobTheBuilderGame',
  components: {
    GameBackground
  },
  data () {
    return {
      title: 'Bob The Builder',
      subtitle: 'A building excercise using very limited material'
    }
  }
}
</script>
