<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-autocomplete
      v-for="n in nbrofQuestions"
      :key="n"
      outlined
      rounded
      v-model="answers[n-1]"
      :items="items"
      :disabled="isReady"
      no-data-text="No city found"
      :label="'City #' + n"
      autocomplete="new"

    />
    <GameToggleButton
      class="mt-5"
      :selected="enteredPreviously"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground'
import GameToggleButton from '@/components/GameToggleButton'
import { cities } from './allcities.js'

export default {
  name: 'TheTouristGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  data () {
    return {
      title: 'The Tourist',
      subtitle: 'Where are we?',
      databaseString: 'v1',
      isReady: false,
      isSending: false,
      items: cities,
      notReadyLabel: 'Traveled Enough?',
      readyLabel: 'Great!',
      answers: {},
      nbrofQuestions: 10
    }
  },
  methods: {
    async onToggledClick (toggled) {
      this.isReady = toggled
      this.isSending = true
      if (this.isReady) {
        const points = await this.correctAnswers(this.answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, this.answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answer) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0
      for (let i = 0; i < answer.length; i++) {
        if (answer[i] === facts[i]) {
          matches += 1
        }
      }
      return matches
    }
  },
  mounted () {
    if (this.enteredPreviously !== undefined) {
      this.isReady = this.enteredPreviously
    }
    if (this.previousValues !== undefined) {
      this.answers = this.previousValues
    } else {
      for (let i = 0; i < this.nbrofQuestions; i++) {
        this.answers[i] = ''
      }
    }
  }
}
</script>
