<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-dialog
      class="d-flex justify-center"
      :value="imageDialog"
      max-width="1000"
      @click:outside="imageDialog = false"
    >
      <v-img
        contain
        :src="highlightedImage"
        @click="imageDialog = false"
      />
    </v-dialog>
    <v-expansion-panels
      v-model="currentOpenPanel"
    >
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
        :disabled="isReady"
      >
        <v-expansion-panel-header>
          Set #{{i+1}}
          <v-icon
            v-if="item.active !== undefined"
            class="justify-end"
            color="green"
            v-text="'mdi-checkbox-marked-circle'"
          />

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-item-group
            v-model="item.active"
          >
            <v-row>
              <v-col
                v-for="(picture, d) in item.pictures"
                :key="d"
                class="d-flex child-flex"
                cols="6"
              >
                <v-item v-slot="{ toggle }">
                  <v-card
                    outlined
                  >
                      <v-img
                        :src="imageSearchPath + picture"
                        v-click-dblclick="(e, isDoubleClick) => onClick(toggle, picture, isDoubleClick)"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        <v-fade-transition>
                          <v-overlay
                            opacity="0.8"
                            v-if="item.active !== undefined && item.active !== d"
                            absolute
                          >
                            <v-icon
                              color="white"
                              size="48"
                              v-text="'mdi-close-circle-outline'"
                            />
                          </v-overlay>
                        </v-fade-transition>
                        </v-row>
                      </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <GameToggleButton
      class="mt-5"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      :selected="enteredPreviously"
      @toggleClick="onReadyButtonClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'
import vueClickHelper from 'vue-click-helper'

export default {
  name: 'MisfitGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  directives: {
    'click-dblclick': vueClickHelper
  },
  data () {
    return {
      title: 'Misfit',
      subtitle: 'Pick the odd one out!',
      databaseString: 'v2',
      readyLabel: 'Bring it on!',
      notReadyLabel: 'Are we ready?',
      isReady: false,
      isSending: false,

      // Game specific settings
      imageDialog: false,
      imageSearchPath: '/gameassets/Misfit/images_v2/',
      longpressInterval: 500,
      highlightedImage: null,
      currentOpenPanel: undefined,
      items: [
        {
          name: 'Set 1',
          pictures: ['Paper.jpg', 'Scissors.jpg', 'Rock.jpg', 'Tunnel.jpg'],
          active: undefined
        },
        {
          name: 'Set 2',
          pictures: ['SHM.jpg', 'Nickelback.jpg', 'IronMaiden.jpg', 'GoldFish.jpg'],
          active: undefined
        },
        {
          name: 'Set 3',
          pictures: ['Tiger.jpg', 'Tap.jpg', 'Swing.jpg', 'Salsa.jpg'],
          active: undefined
        },
        {
          name: 'Set 4',
          pictures: ['Heath.jpg', 'Jim.jpg', 'Paul.jpg', 'DeVito.jpg'],
          active: undefined
        },
        {
          name: 'Set 5',
          pictures: ['Manhattan.jpg', 'Sex.jpg', 'Screwdriver.jpg', 'BigBen.jpg'],
          active: undefined
        },
        {
          name: 'Set 6',
          pictures: ['Suits.jpg', 'Jordgubbe.jpg', 'Cards.jpg', 'Staltrad.jpg'],
          active: undefined
        },
        {
          name: 'Set 7',
          pictures: ['Mountain.jpg', 'Toilet.jpg', 'Pairs.jpg', 'House.jpg'],
          active: undefined
        },
        {
          name: 'Set 8',
          pictures: ['Chicken.jpg', 'Lettuce.jpg', 'Tomato.jpg', 'Bacon.jpg'],
          active: undefined
        },
        {
          name: 'Set 9',
          pictures: ['Lion.jpg', 'Mars.jpg', 'Eminem.jpg', 'Astronaut.jpg'],
          active: undefined
        },
        {
          name: 'Set 10',
          pictures: ['Drawing.jpg', 'Peanuts.jpg', 'Chili.jpg', 'Boys.jpg'],
          active: undefined
        }
      ]
    }
  },
  methods: {
    async onReadyButtonClick (toggled) {
      this.isReady = toggled
      this.currentOpenPanel = undefined
      this.isSending = true

      if (this.isReady) {
        const answers = {}
        // Convert model values to actual picture name
        for (const index in this.items) {
          const key = this.items[index].name
          const pictureName = this.items[index].pictures[this.items[index].active]
          if (pictureName !== undefined) {
            answers[key] = pictureName
          } else {
            answers[key] = ''
          }
        }
        const points = await this.correctAnswers(answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let points = 0
      for (const key in facts) {
        if (facts[key] === answers[key]) {
          points += 1
        }
      }
      return points
    },
    showHighlight (val) {
      this.highlightedImage = this.imageSearchPath + val
      this.imageDialog = true
    },
    onClick (toggle, val, isDoubleClick) {
      this.imageDialog = false
      if (isDoubleClick) {
        this.showHighlight(val)
      } else {
        toggle()
      }
    }
  },
  mounted () {
    if (this.previousValues !== undefined) {
      for (const i in this.previousValues) {
        for (const j in this.items) {
          if (i === this.items[j].name) {
            if (this.previousValues[i] !== '') {
              this.items[j].active = this.items[j].pictures.indexOf(this.previousValues[i])
            }
            break
          }
        }
      }
    }
    this.isReady = this.enteredPreviously
  }
}
</script>
