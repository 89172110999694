<template>
  <v-fade-transition
    mode="out-in"
    @enter="(e1) => { $emit('game-enter', e1) }"
    @leave="(e1) => { $emit('game-leave', e1) }"
  >
  <GameBackground
    :title="highscoreTitle"
    :subtitle="highscoreSubtitle"
  >
    <v-simple-table
      class="px-2"
    >
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(score, index) in highScores"
            :key="index"
          >
            <td
              class="py-5"
            >
              <v-chip
                :ripple="false"
                color="yellow accent-4"
                v-if="index + 1 === 1"
                style="vertical-align: baseline"
              >
                <span class ="subtitle">{{ index+1 }}</span>
              </v-chip>
              <v-chip
                :ripple="false"
                color="blue-grey lighten-3"
                v-else-if="index + 1 === 2"
                style="vertical-align: baseline"
              >
                <span class ="subtitle">{{ index+1 }}</span>
              </v-chip>
              <v-chip
                :ripple="false"
                color="amber accent-3"
                v-else-if="index + 1 === 3"
                style="vertical-align: baseline"
              >
                <span class ="subtitle">{{ index+1 }}</span>
              </v-chip>
            </td>
            <td class="text-left">
              <span
                class="subtitle font-weight-black"
                v-if="index + 1 === 1"
              >{{ score.name }}</span>
              <span
                class="subtitle font-weight-normal"
                v-else-if="index + 1 === 2"
              >{{ score.name }}</span>
              <span
                class="subtitle font-weight-light"
                v-else
              >{{ score.name }}</span>
            </td>
            <td class="text-right">
                <span
                  class="text-h5 fill-height font-weight-bold"
                  v-if="index + 1 === 1"
                >{{ score.points }}</span>
                <span
                  class="text-h5 fill-height"
                  v-else
                >{{ score.points }}</span>
                <span class="body fill-height pl-2">p</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </GameBackground>
  </v-fade-transition>
</template>

<script>
import GameBackground from '@/components/GameBackground'
export default {
  name: 'ScoreFlash',
  props: [
    'topScores',
    'teamName',
    'teamCode',
    'highscoreTitle',
    'highscoreSubtitle'
  ],
  components: {
    GameBackground
  },
  data () {
    return {
      maxInHighscore: 4,
      backgroundcolor: '#FFFFFF'
    }
  },
  computed: {
    highScores () {
      if (this.topScores === undefined || Object.keys(this.topScores).length === 0) {
        return []
      }
      return this.topScores.slice(0, this.maxInHighscore)
    },
    currentScore () {
      if (this.topScores === undefined) {
        return 0
      } else {
        for (const i in this.topScores) {
          if (this.topScores[i].code === this.teamCode) {
            return this.topScores[i].points
          }
        }
        return 0
      }
    }
  }

}
</script>
