<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
  <v-autocomplete
    v-model="greenValues"
    rounded
    prepend-icon="mdi-glass-cocktail"
    item-color="green"
    background-color="green lighten-5"
    label="The Green Shot"
    :rules="[maxingredients]"
    outlined
    multiple
    :disabled="isReady"
    :items="greenItems"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <v-autocomplete
    v-model="orangeValues"
    prepend-icon="mdi-glass-cocktail"
    item-color="orange"
    background-color="orange lighten-5"
    label="The Orange Shot"
    :rules="[maxingredients]"
    rounded
    outlined
    multiple
    :disabled="isReady"
    :items="orangeItems"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <GameToggleButton
    class="mt-5"
    :readyLabel="buttonReadyLabel"
    :notReadyLabel="buttonNotReadyLabel"
    :loading="isSending"
    :disabled="disableButton"
    :selected="enteredPreviously"
    @toggleClick="onToggledClick"
  />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'

export default {
  name: 'TasteBudsGame', // Used in the database to find answers
  components: {
    GameBackground,
    GameToggleButton
  },
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  data () {
    return {
      title: 'Taste Buds',
      subtitle: 'What 5 ingredients can you taste in each drink?',
      databaseString: 'v1', // To support seperate versions of the same game
      isSending: false,
      disableButton: false,
      maxingredients: (v) => v.length <= 5 || 'Max five ingredients',
      buttonReadyLabel: 'Answers are locked!',
      buttonNotReadyLabel: 'Lock answers?',
      isReady: false,
      greenValues: [],
      orangeValues: [],
      greenItems:
      [
        'Lime', 'Tabasco', 'Cinnamon', 'Pear',
        'Orange', 'Ginger', 'Pepper', 'Kiwi',
        'Apple', 'Passionfruit', 'Pineapple', 'Celery',
        'Spirulina', 'Green Kale', 'Spinach', 'Cucumber'
      ],
      orangeItems:
      [
        'Lemon', 'Chili', 'Ginger', 'Green Tea',
        'Orange', 'Mango', 'Pepper', 'Carrot',
        'Apple', 'Salt', 'Pineapple', 'Turmeric',
        'Goji Berry', 'Cardemum', 'Elderflower', 'Cinnamon'
      ]
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const answers = {}
        answers.green = this.greenValues
        answers.orange = this.orangeValues
        const points = await this.correctAnswers(answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0

      for (let i = 0; i < answers.green.length; i++) {
        if (facts.green.includes(answers.green[i])) {
          matches += 1
        }
      }
      for (let i = 0; i < answers.orange.length; i++) {
        if (facts.orange.includes(answers.orange[i])) {
          matches += 1
        }
      }

      return matches
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    if (this.previousValues !== undefined) {
      this.greenValues = this.previousValues.green
      this.orangeValues = this.previousValues.orange
    }
  }
}
</script>

<style scoped>
.capitalize >>> input{
    text-transform: capitalize;
}
</style>
