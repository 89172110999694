<template>
  <div class="no-gutters">
    <v-fade-transition
      mode="out-in"
      @enter="(e1) => { $emit('game-enter', e1) }"
      @leave="(e1) => { $emit('game-leave', e1) }"
    >
      <component
        :previousValues="historicGameValue(scenario[currentQuestion].name)"
        :enteredPreviously="historicGameReady(scenario[currentQuestion].name)"
        :is="scenario[currentQuestion].name"
        :selected="historicGameReady"
        :title="scenario[currentQuestion].friendlyname"
        :loading="toggleButtonLoading"
        @gameReady="gameReadyClicked"
        @gameNotReady="gameNotReadyClicked"
      />
    </v-fade-transition>
  </div>
</template>

<script>
// General imports
import { mapGetters } from 'vuex'

// Game imports
import TasteBudsGame from '@/games/TasteBuds/TasteBuds'
import ShazamGame from '@/games/Shazam/Shazam'
import TheCaseGame from '@/games/TheCase/TheCase'
import HollywoodGame from '@/games/Hollywood/Hollywood'
import ShapeUpGame from '@/games/ShapeUp/ShapeUp'
import MisfitGame from '@/games/Misfit/Misfit'
import MemoryGame from '@/games/Memory/Memory'
import TiebreakerGame from '@/games/Tiebreaker/Tiebreaker'
import BobTheBuilderGame from '@/games/BobTheBuilder/BobTheBuilder'
import ThemeSongGame from '@/games/ThemeSong/ThemeSong'

export default {
  name: 'TheUltimateScenario',
  components: {
    TasteBudsGame,
    TheCaseGame,
    HollywoodGame,
    ShazamGame,
    ShapeUpGame,
    MisfitGame,
    MemoryGame,
    TiebreakerGame,
    BobTheBuilderGame,
    ThemeSongGame
  },
  computed: {
    ...mapGetters('client', [
      'gameStatus',
      'currentQuestion',
      'historicGameReady',
      'historicGameValue'
    ]),
    ...mapGetters([
      'scenarioGamesByComponentName'
    ])
  },
  data () {
    return {
      toggleButtonLoading: false,
      scenario: undefined
    }
  },
  methods: {
    async gameReadyClicked (componentName, gameVersion, answers, points) {
      await this.$emit('gameToggled', componentName, gameVersion, answers, points, true)
    },
    gameNotReadyClicked (componentName, gameVersion) {
      this.$emit('gameToggled', componentName, gameVersion, undefined, undefined, false)
    }
  },
  // Called before the first render!
  // TODO: Send the scenario in as a prop instead?
  beforeMount () {
    this.scenario = this.scenarioGamesByComponentName(this.$options.name)
  }
}
</script>
