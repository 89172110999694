<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-dialog
      class="d-flex justify-center"
      :value="imageDialog"
      max-width="1000"
      @click:outside="imageDialog = false"
    >
      <v-img
        contain
        @click="imageDialog = false"
        :src="highlightedImage"
      />
    </v-dialog>
    <v-expansion-panels
      v-model="currentOpenPanel"
    >
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
        :disabled="isReady"
      >
        <v-expansion-panel-header>
          Song #{{i+1}}
          <v-icon
            v-if="item.active !== undefined"
            class="justify-end"
            color="green"
            v-text="'mdi-checkbox-marked-circle'"
          />

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-item-group
            v-model="item.active"
          >
            <v-row>
              <v-col
                v-for="(picture, d) in item.pictures"
                :key="d"
                class="d-flex child-flex"
                cols="6"
              >
                <v-item v-slot="{ toggle }">
                  <v-card
                    outlined
                  >
                      <v-img
                        :src="imageSearchPath + picture"
                        aspect-ratio="1"
                        v-click-dblclick="(e, isDoubleClick) => onClick(toggle, picture, isDoubleClick)"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        <v-fade-transition>
                          <v-overlay
                            opacity="0.8"
                            v-if="item.active !== undefined && item.active !== d"
                            absolute
                          >
                            <v-icon
                              color="white"
                              size="48"
                              v-text="'mdi-close-circle-outline'"
                            />
                          </v-overlay>
                        </v-fade-transition>
                        </v-row>
                      </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <GameToggleButton
      class="mt-5"
      :readyLabel="readyLabel"
      :loading="isSending"
      :notReadyLabel="notReadyLabel"
      :selected="enteredPreviously"
      @toggleClick="onReadyButtonClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'
import vueClickHelper from 'vue-click-helper'

export default {
  name: 'ShazamGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  directives: {
    'click-dblclick': vueClickHelper
  },
  data () {
    return {
      title: 'Shazam',
      subtitle: 'Match 10 great songs with correct artist/artists',
      databaseString: 'v1',
      readyLabel: 'Bring it on!',
      notReadyLabel: 'Are we ready?',
      isReady: false,
      isSending: false,

      // Game specific settings
      imageDialog: false,
      imageSearchPath: '/gameassets/Shazam/images_v1/',
      longpressInterval: 500,
      highlightedImage: null,
      currentOpenPanel: undefined,
      items: [
        {
          name: 'Song 1',
          pictures: ['boys2.jpg', 'boys1.jpg', 'boys3.jpg', 'boys4.jpg'],
          active: undefined
        },
        {
          name: 'Song 2',
          pictures: ['rock1.jpg', 'rock2.jpg', 'rock4.jpg', 'rock3.jpg'],
          active: undefined
        },
        {
          name: 'Song 3',
          pictures: ['old4.jpg', 'old1.jpg', 'old2.jpg', 'old3.jpg'],
          active: undefined
        },
        {
          name: 'Song 4',
          pictures: ['pop1.jpg', 'pop2.jpg', 'pop4.jpg', 'pop3.jpg'],
          active: undefined
        },
        {
          name: 'Song 5',
          pictures: ['popg1.jpg', 'popg2.jpg', 'popg3.jpg', 'popg4.jpg'],
          active: undefined
        },
        {
          name: 'Song 6',
          pictures: ['rnb1.jpg', 'rnb4.jpg', 'rnb2.jpg', 'rnb3.jpg'],
          active: undefined
        },
        {
          name: 'Song 7',
          pictures: ['duo3.jpg', 'duo4.jpg', 'duo2.jpg', 'duo1.jpg'],
          active: undefined
        },
        {
          name: 'Song 8',
          pictures: ['band1.jpg', 'band4.jpg', 'band2.jpg', 'band3.jpg'],
          active: undefined
        },
        {
          name: 'Song 9',
          pictures: ['country4.jpg', 'country2.jpg', 'country3.jpg', 'country1.jpg'],
          active: undefined
        },
        {
          name: 'Song 10',
          pictures: ['bandg1.jpg', 'bandg3.jpg', 'bandg2.jpg', 'bandg4.jpg'],
          active: undefined
        }
      ]
    }
  },
  methods: {
    async onReadyButtonClick (toggled) {
      this.isReady = toggled
      this.currentOpenPanel = undefined
      this.isSending = true

      if (this.isReady) {
        const answers = {}
        // Convert model values to actual picture name
        for (const index in this.items) {
          const key = this.items[index].name
          const pictureName = this.items[index].pictures[this.items[index].active]
          if (pictureName !== undefined) {
            answers[key] = pictureName
          } else {
            answers[key] = ''
          }
        }
        const points = await this.correctAnswers(answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let points = 0
      for (const key in facts) {
        if (facts[key] === answers[key]) {
          points += 1
        }
      }
      return points
    },
    showHighlight (val) {
      this.highlightedImage = this.imageSearchPath + val
      this.imageDialog = true
    },
    onClick (toggle, val, isDoubleClick) {
      this.imageDialog = false
      if (isDoubleClick) {
        this.showHighlight(val)
      } else {
        toggle()
      }
    }
  },
  mounted () {
    if (this.previousValues !== undefined) {
      for (const i in this.previousValues) {
        for (const j in this.items) {
          if (i === this.items[j].name) {
            if (this.previousValues[i] !== '') {
              this.items[j].active = this.items[j].pictures.indexOf(this.previousValues[i])
            }
            break
          }
        }
      }
    }
    this.isReady = this.enteredPreviously
  }
}
</script>
