export var companies =
[
  'Walmart',
  'Exxon Mobil',
  'Chevron',
  'Berkshire Hathaway',
  'Apple',
  'Phillips 66',
  'General Motors',
  'Ford Motor',
  'General Electric',
  'Valero Energy',
  'AT&T',
  'CVS Caremark',
  'Fannie Mae',
  'UnitedHealth Group',
  'McKesson',
  'Verizon',
  'Hewlett-Packard',
  'J.P. Morgan Chase',
  'Costco Wholesale',
  'Bank of America',
  'Cardinal Health',
  'Kroger',
  'Marathon Petroleum',
  'Citigroup',
  'Wells Fargo',
  'Boeing',
  'Procter & Gamble',
  'Freddie Mac',
  'Home Depot',
  'Microsoft',
  'Amazon.com',
  'Target',
  'Walgreen Co.',
  'WellPoint',
  'Johnson & Johnson',
  'State Farm',
  'MetLife',
  'PepsiCo',
  'Comcast',
  'United Technologies',
  'Google',
  'ConocoPhillips',
  'Dow Chemical',
  'Caterpillar',
  'United Parcel Service',
  'Pfizer',
  'Lowe’s Companies',
  'Intel Corporation',
  'Energy Transfer Equity',
  'Cisco Systems',
  'Enterprise Products',
  'Aetna Inc.',
  'The Coca-Cola Company',
  'Lockheed Martin',
  'Best Buy Co.',
  'The Walt Disney Company',
  'CHS Inc.',
  'Sysco Corporation',
  'FedEx',
  'Merck & Co.',
  'INTL FCStone Inc.',
  'Safeway Inc.',
  'Johnson Controls',
  'Ingram Micro Inc.',
  'Plains GP Holdings, L.P.',
  'World Fuel',
  'Prudential Financial',
  'Humana Inc.',
  'The Goldman Sachs Group',
  'Tesoro Corporation',
  'Liberty Mutual',
  'Honeywell',
  'United Continental',
  'HCA Holdings',
  'Deere & Company',
  'Delta Air Lines',
  'Oracle Corporation',
  'Morgan Stanley',
  'Hess Corporation',
  'Twenty-First Century Fox',
  'Sears',
  'Mondelez International',
  'American Express Company',
  'The Allstate Corporation',
  'Tyson Foods',
  'Supervalu Inc.',
  'TIAA-CREF',
  'CIGNA Corporation',
  'DIRECTV',
  'Philip Morris',
  '3M Company',
  'Time Warner Inc.',
  'Halliburton Company',
  'Publix Super Markets',
  'McDonald’s Corporation',
  'Macy’s',
  'The TJX Companies',
  'Fluor Corporation',
  'Tech Data Corporation',
  'American Airlines',
  'The Travelers Companies',
  'Nike',
  'Avnet',
  'Rite Aid Corporation',
  'Exelon Corporation',
  'Qualcomm Incorporated',
  'Emerson Electric Co.',
  'Northrop Grumman',
  'Duke Energy Corporation',
  'Capital One',
  'Aflac Incorporated',
  'Raytheon Company',
  'Staples',
  'EMC Corporation',
  'Eli Lilly and Company',
  'Alcoa Inc.',
  'National Oilwell Varco',
  'Baker Hughes',
  'US Foods',
  'Time Warner Cable Inc.',
  'Union Pacific',
  'Abbott Laboratories',
  'Xerox Corporation',
  'Arrow Electronics, Inc',
  'Kimberly-Clark',
  'U.S. Bancorp',
  'United Services Automobile',
  'Icahn Enterprises L.P.',
  'Kodak',
  'ManpowerGroup Inc.',
  'Global Partners LP',
  'Goodyear Tire & Rubber',
  'PBF Energy Inc.',
  'Danaher Corporation',
  'Nucor Corporation',
  'Kohl’s Corporation',
  'AbbVie Inc.',
  'Whirlpool Corporation',
  'Amgen Inc.',
  'Jabil Circuit',
  'Kraft Foods Group',
  'CenturyLink',
  'General Mills',
  'Southwest Airlines Co.',
  'Altria Group',
  'AutoNation',
  'Colgate-Palmolive',
  'Cummins Inc.',
  'PACCAR Inc',
  'The Southern Company',
  'Illinois Tool Works Inc.',
  'Medtronic',
  'The AES Corporation',
  'Murphy USA Inc.',
  'Lear Corporation',
  'The Gap',
  'Apache Corporation',
  'eBay Inc.',
  'CBS Corporation',
  'PG&E Corporation',
  'ConAgra Foods',
  'Western Digital',
  'Marathon Oil Corporation',
  'Baxter International',
  'VISA',
  'PPG Industries',
  'NextEra Energy',
  'Community Health Systems',
  'Loews Corporation',
  'Penske Automotive Group',
  'FirstEnergy Corp.',
  'Starbucks Corporation',
  'Monsanto Company',
  'Kellogg Company',
  'Land O’Lakes',
  'ONEOK',
  'Red Cross',
  'Omnicom Group Inc.',
  'EOG Resources',
  'DISH Network Corporation',
  'Genuine Parts Company',
  'Kinder Morgan',
  'Waste Management',
  'The Chubb Corporation',
  'Viacom Inc.',
  'Las Vegas Sands Corp.',
  'Dominion Resources',
  'Ecolab Inc.',
  'Smithfield Foods',
  'Yum! Brands',
  'Whole Foods Market',
  'Marriott International',
  'C. H. Robinson Worldwide',
  'Edison International',
  'Toys “R” Us',
  'Nordstrom',
  'Consolidated Edison',
  'Texas Instruments',
  'Textron Inc.',
  'Tenet Healthcare',
  'DaVita HealthCare',
  'CSX Corporation',
  'Lincoln National',
  'Praxair',
  'PPL Corporation',
  'J.C. Penney Company',
  'Peter Kiewit Sons’',
  'Jacobs Engineering',
  'Heinz',
  'CarMax',
  'V.F. Corporation',
  'Entergy Corporation',
  'NRG Energy',
  'Office Depot',
  'Ameriprise Financial',
  'Gilead Sciences',
  'Centene Corporation',
  'Huntsman Corporation',
  'Health Net',
  'Stanley Black & Decker',
  'URS Corporation',
  'Xcel Energy Inc.',
  'Bed Bath & Beyond Inc.',
  'Synnex Corporation',
  'First Data Corporation',
  'AGCO Corporation',
  'L Brands',
  'Hertz Global Holdings',
  'CDW Corporation',
  'CST Brands',
  'Sempra Energy',
  'BB&T Corporation',
  'Devon Energy Corporation',
  'Family Dollar Stores',
  'Unum Group',
  'Ally Financial Inc.',
  'State Street Corporation',
  'Air Products & Chemicals',
  'Ross Stores',
  'Sherwin-Williams',
  'Estée Lauder',
  'BlackRock',
  'Western Refining',
  'Avon Products',
  'The Mosaic Company',
  'Dean Foods Company',
  'MGM Resorts',
  'KKR & Co. L.P.',
  'Hilton Worldwide',
  'DTE Energy Company',
  'Genworth Financial',
  'Henry Schein',
  'Rock-Tenn Company',
  'WellCare Health Plans',
  'W.W. Grainger',
  'Eastman Chemical Company',
  'AutoZone',
  'Dover Corporation',
  'Micron Technology',
  'Owens & Minor',
  'Assurant',
  'GameStop Corp.',
  'Stryker Corporation',
  'Group 1 Automotive',
  'Cognizant Technology',
  'Sonic Automotive',
  'Autoliv',
  'Hormel Foods Corporation',
  'Motorola Solutions',
  'Crown Holdings',
  'SunTrust Banks',
  'Campbell Soup Company',
  'Fidelity National',
  'HD Supply Holdings',
  'Caesars Entertainment',
  'Darden Restaurants',
  'Weyerhaeuser Company',
  'Ball Corporation',
  'Masco Corporation',
  'Republic Services',
  'MasterCard Incorporated',
  'Broadcom Corporation',
  'Reynolds American Inc.',
  'PVH Corp.',
  'Charter Communications',
  'AECOM',
  'CenterPoint Energy',
  'Pacific Life',
  'Franklin Resources',
  'Tenneco Inc.',
  'TravelCenters',
  'Avis Budget Group',
  'Facebook',
  'Porsche',
  'Dollar Tree',
  'Corning Incorporated',
  'Ashland Inc.',
  'Sealed Air Corporation',
  'Oshkosh Corporation',
  'Coca-Cola Enterprises',
  'WESCO International',
  'Applied Materials',
  'Visteon Corporation',
  'BorgWarner Inc.',
  'Steel Dynamics',
  'Jarden Corporation',
  'Mohawk Industries',
  'Terex Corporation',
  'Northeast Utilities',
  'KBR',
  'Fifth Third Bancorp',
  'UGI Corporation',
  'CBRE Group',
  'The Hershey Company',
  'FMC Technologies',
  'The Pantry',
  'Owens-Illinois',
  'Ralph Lauren Corporation',
  'Biogen Idec Inc.',
  'PetSmart',
  'Mylan Inc.',
  'Symantec Corporation',
  'Ameren Corporation',
  'The Williams Companies',
  'Barnes & Noble',
  'The Priceline Group Inc.',
  'Agilent Technologies',
  'Dana Holding Corporation',
  'Dillard’s',
  'Seaboard Corporation',
  'Vanguard Health Systems',
  'Casey’s General Stores',
  'O’Reilly Automotive',
  'Molina Healthcare',
  'CMS Energy Corporation',
  'Targa Resources Corp.',
  'Quanta Services',
  'Celanese Corporation',
  'Foot Locker',
  'Celgene Corporation',
  'Adidas',
  'Advance Auto Parts',
  'Mattel',
  'Live Nation',
  'Ryder System',
  'EMCOR Group',
  'Allergan',
  'W.R. Berkley Corporation',
  'Rockwell Automation',
  'NetApp',
  'Ingredion Incorporated',
  'Level 3 Communications',
  'Calpine Corporation',
  'Omnicare',
  'Erie Insurance Group',
  'SLM Corporation',
  'D.R. Horton',
  'CC Media Holdings',
  'Anixter',
  'Dick’s Sporting Goods',
  'SanDisk Corporation',
  'NCR Corporation',
  'IMAX',
  'United Natural Foods',
  'Windstream Holdings',
  'Dr Pepper Snapple Group',
  'Lennar Corporation',
  'Sanmina',
  'Harley-Davidson',
  'CONSOL Energy Inc.',
  'The J.M. Smucker Company',
  'Newell Rubbermaid Inc.',
  'Kindred Healthcare',
  'Leidos Holdings Inc.',
  'PulteGroup',
  'NiSource Inc.',
  'Graybar Electric Company',
  'Integrys Energy Group',
  'The Clorox Company',
  'Wynn Resorts, Limited',
  'The Andersons',
  'Harbinger Group Inc.',
  'MeadWestvaco Corporation',
  'Discovery Communications',
  'Spectra Energy Corp',
  'St. Jude Medical',
  'CF Industries Holdings',
  'Con-way Inc.',
  'JetBlue',
  'Calumet Specialty',
  'Kelly Services',
  'Domtar Corporation',
  'Murphy Oil Corporation',
  'Harris Corporation',
  'Asbury Automotive Group',
  'Big Lots',
  'Advanced Micro Devices',
  'Owens Corning',
  'Realogy Holdings Corp.',
  'Host Hotels & Resorts',
  'MRC Global Inc.',
  'Simon Property Group',
  'Tractor Supply Company',
  'Gannett Co.',
  'Alaska Air Group',
  'Insight Enterprises',
  'McGraw Hill Financial',
  'Buckeye Partners, L.P.',
  'Quintiles',
  'American Financial Group',
  'United Stationers Inc.',
  'Coach',
  'LKQ Corporation',
  'Noble Energy',
  'Bemis Company',
  'Joy Global Inc.',
  'Wyndham',
  'NII Holdings',
  'Lorillard',
  'Alleghany Corporation',
  'Airgas',
  'First American',
  'United Rentals'
]
