<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
  <v-autocomplete
    v-model="firstValues"
    rounded
    prepend-icon="mdi-glass-cocktail"
    label="Nr 1 - Drink"
    :rules="[maxingredients]"
    outlined
    color="green"
    multiple
    :disabled="isReady"
    :items="checkIngredients(secondValues, thirdValues, fourthValues)"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <v-autocomplete
    v-model="secondValues"
    prepend-icon="mdi-glass-flute"
    label="Nr 2 - Shot"
    :rules="[maxingredients]"
    rounded
    outlined
    color="green"
    multiple
    :disabled="isReady"
    :items="checkIngredients(thirdValues, fourthValues, firstValues)"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <v-autocomplete
    v-model="thirdValues"
    prepend-icon="mdi-glass-tulip"
    label="Nr 3 - Drink"
    :rules="[maxingredients]"
    rounded
    color="green"
    outlined
    multiple
    :disabled="isReady"
    :items="checkIngredients(firstValues, secondValues, fourthValues)"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <v-autocomplete
    v-model="fourthValues"
    prepend-icon="mdi-glass-stange"
    label="Nr 4 - Drink"
    :rules="[maxingredients]"
    rounded
    color="green"
    outlined
    multiple
    :disabled="isReady"
    :items="checkIngredients(firstValues, secondValues, thirdValues)"
    no-data-text="No ingredient found"
    @update:error="(v) => disableButton = v"
  />
  <GameToggleButton
    class="mt-5"
    :readyLabel="buttonReadyLabel"
    :notReadyLabel="buttonNotReadyLabel"
    :loading="isSending"
    :disabled="disableButton"
    :selected="enteredPreviously"
    @toggleClick="onToggledClick"
  />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'

export default {
  name: 'TasteBudsGame', // Used in the database to find answers
  components: {
    GameBackground,
    GameToggleButton
  },
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  data () {
    return {
      title: 'Cocktail',
      subtitle: 'What are the 3 main ingredients in each drink?',
      databaseString: 'v2', // To support seperate versions of the same game
      isSending: false,
      disableButton: false,
      maxingredients: (v) => v.length <= 3 || 'Max three ingredients',
      buttonReadyLabel: 'Cheers!',
      buttonNotReadyLabel: 'Lock answers?',
      isReady: false,

      // TODO Make it more flexible, with a list and a number
      firstValues: [],
      secondValues: [],
      thirdValues: [],
      fourthValues: [],
      availableIngredients:
      [
        'Cachaca', 'Pineapple Juice', 'Coffee', 'Lime',
        'Cream', 'Orange Juice', 'Sugar', 'Galliano',
        'Rum', 'Coconut', 'Tequila', 'Grenadine'
      ]
    }
  },
  methods: {
    checkIngredients () {
      const ingredientArgs = arguments
      return this.availableIngredients.filter(function (value, index, arr) {
        for (let i = 0; i < ingredientArgs.length; i++) {
          const inputValues = ingredientArgs[i]
          if (Array.isArray(inputValues) && inputValues.find(v => v === value) !== undefined) {
            return false
          } else if (inputValues === value) {
            return false
          }
        }
        return true
      })
    },
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const answers = {}
        answers.first = this.firstValues
        answers.second = this.secondValues
        answers.third = this.thirdValues
        answers.fourth = this.fourthValues
        const points = await this.correctAnswers(answers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, answers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0

      // First drink
      for (let i = 0; i < answers.first.length; i++) {
        if (facts.first.includes(answers.first[i])) {
          matches += 1
        }
      }

      // Second drink
      for (let i = 0; i < answers.second.length; i++) {
        if (facts.second.includes(answers.second[i])) {
          matches += 1
        }
      }

      // Third drink
      for (let i = 0; i < answers.third.length; i++) {
        if (facts.third.includes(answers.third[i])) {
          matches += 1
        }
      }

      // Fourth drink
      for (let i = 0; i < answers.fourth.length; i++) {
        if (facts.fourth.includes(answers.fourth[i])) {
          matches += 1
        }
      }

      return matches
    }
  },
  mounted () {
    this.isReady = this.enteredPreviously
    if (this.previousValues !== undefined) {
      this.firstValues = this.previousValues.first
      this.secondValues = this.previousValues.second
      this.thirdValues = this.previousValues.third
      this.fourthValues = this.previousValues.fourth
    }
  }
}
</script>

<style scoped>
.capitalize >>> input{
    text-transform: capitalize;
}
</style>
