<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-dialog
      class="d-flex justify-center"
      :value="imageDialog"
      max-width="1000"
      @click:outside="imageDialog = false"
    >
      <v-img
        contain
        :src="highlightedImage"
        @click="imageDialog = false"
      />
    </v-dialog>
    <v-expansion-panels
      v-model="currentOpenPanel"
    >
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
        :disabled="isReady"
      >
        <v-expansion-panel-header>
          {{ item.name }}
          <v-icon
            v-if="item.active !== undefined"
            class="justify-end"
            color="green"
            v-text="'mdi-checkbox-marked-circle'"
          />

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-item-group
            v-model="item.active"
          >
            <v-row>
              <v-col
                v-for="(picture, d) in item.pictures"
                :key="d"
                class="d-flex child-flex"
                cols="6"
              >
                <v-item v-slot="{ toggle }">
                  <v-card
                    outlined
                  >
                      <v-img
                        :src="imageSearchPath + picture"
                        v-click-dblclick="(e, isDoubleClick) => onClick(toggle, picture, isDoubleClick)"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        <v-fade-transition>
                          <v-overlay
                            opacity="0.8"
                            v-if="item.active !== undefined && item.active !== d"
                            absolute
                          >
                            <v-icon
                              color="white"
                              size="48"
                              v-text="'mdi-close-circle-outline'"
                            />
                          </v-overlay>
                        </v-fade-transition>
                        </v-row>
                      </v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <GameToggleButton
      class="mt-10"
      :readyLabel="readyLabel"
      :notReadyLabel="notReadyLabel"
      :selected="enteredPreviously"
      :loading="isSending"
      @toggleClick="onToggledClick"
    />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'
import vueClickHelper from 'vue-click-helper'

export default {
  name: 'ThemeSongGame',
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  components: {
    GameBackground,
    GameToggleButton
  },
  directives: {
    'click-dblclick': vueClickHelper
  },
  data () {
    return {
      title: 'Theme Songs',
      subtitle: 'Match 10 great songs with the correct TV-series',
      readyLabel: 'Bring it on!',
      notReadyLabel: 'Are we ready?',
      databaseString: 'v1',
      isReady: false,
      isSending: false,

      // Game specific settings
      imageDialog: false,
      imageSearchPath: '/gameassets/ThemeSongs/images_v1/',
      longpressInterval: 500,
      highlightedImage: null,
      currentOpenPanel: undefined,
      items: [
        {
          name: 'Theme #1',
          pictures: ['a1.jpg', 'a2.jpg', 'a3.jpg', 'a4.jpg'],
          active: undefined
        },
        {
          name: 'Theme #2',
          pictures: ['b1.jpg', 'b2.jpg', 'b3.jpg', 'b4.jpg'],
          active: undefined
        },
        {
          name: 'Theme #3',
          pictures: ['c1.jpg', 'c2.jpg', 'c4.jpg', 'c3.jpg'],
          active: undefined
        },
        {
          name: 'Theme #4',
          pictures: ['d1.jpg', 'd4.jpg', 'd2.jpg', 'd3.jpg'],
          active: undefined
        },
        {
          name: 'Theme #5',
          pictures: ['e1.jpg', 'e4.jpg', 'e2.jpg', 'e3.jpg'],
          active: undefined
        },
        {
          name: 'Theme #6',
          pictures: ['f1.jpg', 'f2.jpg', 'f3.jpg', 'f4.jpg'],
          active: undefined
        },
        {
          name: 'Theme #7',
          pictures: ['g1.jpg', 'g2.jpg', 'g3.jpg', 'g4.jpg'],
          active: undefined
        },
        {
          name: 'Theme #8',
          pictures: ['h3.jpg', 'h2.jpg', 'h1.jpg', 'a4.jpg'],
          active: undefined
        },
        {
          name: 'Theme #9',
          pictures: ['i1.jpg', 'i3.jpg', 'i4.jpg', 'i2.jpg'],
          active: undefined
        },
        {
          name: 'Theme #10',
          pictures: ['j3.jpg', 'j4.jpg', 'j2.jpg', 'j1.jpg'],
          active: undefined
        }
      ]
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true
      this.currentOpenPanel = undefined

      if (this.isReady) {
        const formattedAnswers = []
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].active === undefined) {
            formattedAnswers.push(-1)
          } else {
            formattedAnswers.push(this.items[i].active)
          }
        }
        const points = await this.correctAnswers(formattedAnswers)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, formattedAnswers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      const snapshot = await this.$database.ref('facts/' + this.$options.name + '/' + this.databaseString).once('value')
      const facts = snapshot.val()
      let matches = 0

      for (let i = 0; i < answers.length; i++) {
        if (facts[i] === answers[i]) {
          matches += 1
        }
      }

      return matches
    },
    showHighlight (val) {
      this.highlightedImage = this.imageSearchPath + val
      this.imageDialog = true
    },
    onClick (toggle, val, isDoubleClick) {
      this.imageDialog = false
      if (isDoubleClick) {
        this.showHighlight(val)
      } else {
        toggle()
      }
    }
  },
  mounted () {
    if (this.previousValues !== undefined) {
      // If we didn't set values for a specific theme song, we saved it as "-1", check and set it as undefined if that is the case
      for (const i in this.previousValues) {
        const val = this.previousValues[i] !== -1 ? this.previousValues[i] : undefined
        this.items[i].active = val
      }
    }
    this.isReady = this.enteredPreviously
  }
}
</script>
