import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import * as firebase from 'firebase/app'
import 'firebase/database'
import Particles from 'particles.vue'
import VueConfetti from 'vue-confetti'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCdlNX6_NMj1uIE3-Fx9m6fsEvhBwCBYuA',
  authDomain: 'mobileuhgame.firebaseapp.com',
  databaseURL: 'https://mobileuhgame.firebaseio.com',
  projectId: 'mobileuhgame',
  storageBucket: 'mobileuhgame.appspot.com',
  messagingSenderId: '564522927578',
  appId: '1:564522927578:web:2bc4851dc3a1ff45a3ece3',
  measurementId: 'G-2289FLZFMQ'
}

firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false
Vue.prototype.$database = firebase.database()

Vue.use(VueConfetti)
Vue.use(Particles)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
