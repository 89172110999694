<template>
  <GameBackground
    :title="title"
    :subtitle="subtitle"
  >
    <v-text-field
      class="formatted_input"
      v-for="(question, d) in questions"
      :key="d"
      v-model="question.answer"
      :autofocus="d === 0"
      outlined
      :prefix="question.prefix"
      rounded
      :disabled="isReady"
      :suffix="question.suffix"
      :type="question.type"
    />
  <GameToggleButton
    class="mt-5"
    :readyLabel="buttonReadyLabel"
    :notReadyLabel="buttonNotReadyLabel"
    :selected="enteredPreviously"
    :loading="isSending"
    @toggleClick="onToggledClick"
  />
  </GameBackground>
</template>

<script>
import GameBackground from '@/components/GameBackground.vue'
import GameToggleButton from '@/components/GameToggleButton.vue'

export default {
  name: 'MemoryGame',
  components: {
    GameBackground,
    GameToggleButton
  },
  props: [
    'enteredPreviously',
    'previousValues'
  ],
  data () {
    return {
      title: 'Memory',
      subtitle: 'Answer 5 questions from your host',
      databaseString: 'v1',
      buttonReadyLabel: 'Answer sent!',
      buttonNotReadyLabel: 'Are you sure?',
      isReady: false,
      isSending: false,
      openTimeModal: false,
      time: '',
      timeMenu: false,

      questions: [
        {
          prefix: 'Linnea:',
          answer: '',
          type: 'text'
        },
        {
          prefix: 'Louise:',
          answer: '',
          type: 'text'
        },
        {
          prefix: 'Åsa:',
          answer: '',
          type: 'text'
        },
        {
          prefix: 'Olle:',
          answer: '',
          type: 'text'
        }
      ]
    }
  },
  methods: {
    async onToggledClick (value) {
      this.isReady = value
      this.isSending = true

      if (this.isReady) {
        const databaseAnswers = []
        for (let i = 0; i < this.questions.length; i++) {
          databaseAnswers.push(this.questions[i].answer)
        }
        const points = await this.correctAnswers(this.questions)

        // Emitting that we are ready, with component name, the actual answers and the correct value
        await this.$emit('gameReady', this.$options.name, this.databaseString, databaseAnswers, points)
      } else {
        await this.$emit('gameNotReady', this.$options.name, this.databaseString)
      }
      this.isSending = false
    },
    async correctAnswers (answers) {
      // Avoiding mapGetters
      const facts = this.$store.getters['client/modifiedGames'].MemoryGame
      let matches = 0
      for (let i = 0; i < answers.length; i++) {
        // Match text by eliminating caps and stuff like
        if (answers[i].type === 'Text') {
          const formattedAnswer = answers[i].answer.toString().toLowerCase()
          const formattedFact = facts[i].answer.toString().toLowerCase()
          if (formattedAnswer === formattedFact) {
            matches += 10 / answers.length
          }
        } else {
          // Just compare values
          if (answers[i].answer === facts[i].answer) {
            matches += 10 / answers.length
          }
        }
      }
      return matches
    }
  },
  beforeMount () {
    const info = this.$store.getters['client/modifiedGames'].MemoryGame
    if (info !== undefined) {
      this.questions = []
      for (let i = 0; i < info.length; i++) {
        const value = { prefix: info[i].prefix, type: info[i].type, suffix: info[i].suffix, answer: '' }
        this.questions.push(value)
      }
    }
    this.isReady = this.enteredPreviously
    if (this.previousValues !== undefined) {
      for (const index in this.previousValues) {
        this.questions[index].answer = this.previousValues[index]
      }
    }
  }
}
</script>

<style scoped>
.formatted_input >>> input {
    font-weight: bold;
    margin-left:10px;
}
</style>
